<template>
  <v-container id="mainProduct" class="cards-container" style="width:100%; background: transparent !important;" fluid>
    <v-row>
      <v-col cols="12" md="10">
        <v-card class="mx-auto" :outlined="true">
          <v-row style="margin-top: 2rem" justify="center" class="center-div-container">
            <v-col v-for="plan in getMembresiasCorporativas" :key="plan.name" class="center-div-container">
              <v-card class="card d-flex flex-column justify-space-between">
                <v-card-title class="title-target">
                  <img class="horizontal-line" src="../../../assets/linea-blanca.png" alt="" />
                  <span style="color: white; font-size: 14px; width: 80% ">
                    {{ plan.name }}
                  </span>
                  <img class="horizontal-line" src="../../../assets/linea-blanca.png" alt="" />
                </v-card-title>
                <v-card-text class="d-flex flex-column flex-grow-1;">
                  <h2 class="font-weight-bold mt-6">
                    <span class="usd-text" style="margin: 1rem; font-size: 20px;">USD</span><span
                      style="font-size: 35px;">{{ plan.price }}</span>
                  </h2>
                  <v-divider class="my-4 gray accent-6"></v-divider>
                  <p style="font-size: 14px; font-weight: 700; color: #658DC0;">
                    {{ plan.short_description }}
                  </p>
                  <p style="
                      color: #686868;
                      font-style: italic;
                      font-weight: 500;
                      font-size: 12px;
                    ">
                    {{ plan.description }}
                  </p>
                  <v-divider class="my-4 gray accent-6"></v-divider>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="buy(plan)" style="
                      color: white;
                      height: 49.67px;
                      width: 258;
                      margin-bottom: 1rem;
                      font-size: 14px;
                    " color="#E800FF" block>Comprar</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.cards-container {
  margin-top: 1rem;
  margin-bottom: 5rem;
  background-position-y: -10rem;
  height: auto !important;
  background: transparent !important;
}

.title-target {
  background-color: #E800FF !important;
  font-size: small !important;
  font-style: italic;
  color: white;
  height: 5rem;
}

.usd-text {
  font-size: small;
  color: gray;
}

.v-card {
  border: none !important;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 528px;
  width: 277.25px;
  border-radius: 20px !important;
  box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s ease-in-out, width 0.2s ease-in-out,
    height 0.2s ease-in-out;
  border: 2s ease-in-out 10px #304a6f;
}

.horizontal-line {
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  width: 1rem;
  transition: width 0.5s ease-in-out;
}

.card:hover {
  .horizontal-line {
    width: 1.5rem;
  }

  border: 1px solid #304a6f !important;
  cursor: pointer;
  height: 558px;
  width: 293.01px;
  box-shadow: 10px 15px 20px #304a6f !important;
}

.center-div-container {
  justify-content: center;
  margin: auto;
  display: flex;
  background-color:#001E8C;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["getMembresiasCorporativas", "getSelectedProduct"]),
    plans() {
      return getMembresiasCorporativas.values;
    },
  },
  methods: {
    ...mapActions(["updateProducts", "selectProduct"]),
    buy(product_param) {
      this.selectProduct(product_param);
      this.$router.push("/checkout");
      console.log(this.getSelectedProduct);
    },
  },
  async mounted() {
    await Promise.all([this.updateProducts()]);
    console.log(this.getMembresiasCorporativas);
  }
};
</script>
