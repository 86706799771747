<template>
  <div style="background: transparent;"  class="super-card-container" id="mainProduct">
    <div class="img-for-personal-test">
      <img class="align-img-for-personal-test" src="../../../assets/sobre_foto.svg" alt="">
    </div>
    <div class="card-container">
      <div class="img-container">
        <img src="../../../assets/sobre_foto.svg" alt="">
      </div>
      <div class="container-text">
        <div class="icon-and-text-test-disc-personal" style="margin-left: auto;">
          <span style="font-weight: 700; font-family: montserrat; line-height: 25px; color: #FFFFFF;"> Accede al test
          </span>
        </div>
        <div class="icon-and-text-test-disc-personal" style="margin-left: auto;">
          <span style="font-weight: 700; font-family: montserrat; line-height: 25px; color: #FFFFFF"> Entérate de
            nuestros costos </span>
        </div>
        <div class="container-button" style="margin: auto; display: flex;justify-content: center;">
          <v-btn style="font-size: montserrat;background-color:#E800FF;color: white;"
            @click="buy(getTestDiscPersonal[0])">
            comprar
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    reveal: false
  }),
  computed: {
    ...mapGetters(["getTestDiscPersonal", "getSelectedProduct"])
  },
  methods: {
    ...mapActions(["updateProducts", "selectProduct"]),
    async buy(param) {
      this.selectProduct(param);
      this.$router.push("/checkout");
    }
  },
  async mounted() {
    await Promise.all([this.updateProducts()]);
    await this.getTestDiscPersonal;
  }
};
</script>

<style lang="scss">
.img-dimension-for-section {
  width: 27.625rem;
  height: 25.25rem;
}

.img-container {
  display: flex;
  background-image: url("../../../assets/test-disc 1.png");
  background-size: cover;
  background-position: center;
  width: 25rem;
  border-top-right-radius: 4rem;
  border-bottom-left-radius: 4rem;
  position: relative;
}

.img-container img {
  position: absolute;
  top: -3rem;
  left: -6rem
}

.icon-and-text-test-disc-personal {
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  margin-left: 0rem;

  img {
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.icon-and-text-test-disc-personal:hover {
  text-decoration: underline;
}


.super-card-container {
  background-size: contain;
  width: 100%;
  margin: auto;
  justify-content: center;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
}



.container-text {
  text-align: center;


  * {
    text-align: center;
  }
}

.container-button {
  margin-top: 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .img-for-personal-test {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .img-container {
    display: none !important;
  }

  .img-for-personal-test {
    background-image: url("../../../assets/test-disc 1.png");
    background-size: cover;
    background-position: center;
    width: 15rem;
    height: 15rem;
    border-top-right-radius: 4rem;
    border-bottom-left-radius: 4rem;
    position: relative;
    .align-img-for-personal-test{
      width: 40%;
      position: absolute;
      bottom: 10rem;
      right: 12rem;
    }
  }
}
</style>
