import paymentService from "../../services/paymentService";
export default {
  state: () => ({
    checkoutId: "",
    paymentElement: "",
    clientSecret: "",
    billingInfo: {
      name: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
    },
    order: {},
    paymentMethods: [],
    selectedPaymentMethod: {},
  }),
  mutations: {
    setClientSecret(state, secret) {
      state.clientSecret = secret;
    },
    setCheckoutId(state, id) {
      state.checkoutId = id;
    },
    setPaymentElement(state, element) {
      state.paymentElement = element;
    },
    setPaymentMethods(state, methods) {
      state.paymentMethods = methods;
    },
    setBillingInfo(state, billingInfo) {
      state.billingInfo = billingInfo;
    },
    setSelectedPaymentMethod(state, selectedPaymentMethod) {
      state.selectPaymentMethod = selectedPaymentMethod;
    },
    setOrder(state, order) {
      state.order = order;
    },
  },
  actions: {
    async createOrder({ commit }, order) {
      try {
        let data = await paymentService.createOrder(order);
        console.log(data.data);
        return commit("setOrder", data.data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateOrder({ commit }, order) {
      try {
        let data = await paymentService.updateOrder(order);
        return commit("setOrder", data.data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async finishPayment({ commit }, data) {
      try {
        let response = await paymentService.finishPayment(data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async getOrder({ commit }, orderId) {
      try {
        let data = await paymentService.getOrder(orderId);
        return commit("setOrder", data.data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async createCheckoutSession({ commit }, { body, orderId }) {
      try {
        console.log(orderId);
        let data = await paymentService.createCheckoutSession(body, orderId);
        return commit("setClientSecret", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async createPaymentElement({ commit }, { body, orderId }) {
      try {
        console.log(data);
        let data = await paymentService.createPaymentElement(body, orderId);
        return commit("setClientSecret", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async getCheckoutStatus({ commit }, id) {
      try {
        let data = await paymentService.getCheckoutStatus(id);
        return commit("setCheckoutId", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateBilling({ commit }, billingInfo) {
      return commit("setBillingInfo", billingInfo);
    },
    async updatePaymentMethods({ commit }) {
      let methods = await paymentService.getPaymentMethods();
      return commit("setPaymentMethods", methods);
    },
    async selectPaymentMethod({ commit }, selectedPaymentMethod) {
      return commit("setSelectedPaymentMethod", selectedPaymentMethod);
    },
  },
  getters: {
    getClientSecret(state) {
      return state.clientSecret;
    },
    getCheckoutId(state) {
      return state.checkoutId;
    },
    getPaymentMethods(state) {
      return state.paymentMethods;
    },
    getSelectedPaymentMethod(state) {
      return state.selectedPaymentMethod;
    },
    getBillingInfo(state) {
      return state.billingInfo;
    },
    getOrderInfo(state) {
      return state.order;
    },
  },
};
