import productService from "../../services/productService";

export default {
  state: () => ({
    products: [],
    categories: [],
    selectedProduct: {}
  }),
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setSelectedProduct(state, selectedProduct) {
      state.selectedProduct = selectedProduct;
    }
  },
  actions: {
    async updateProducts({ commit }) {
      try {
        let data = await productService.getProducts();
        return commit("setProducts", data);
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateCategories({ commit }) {
      try {
        let data = await productService.getProductsCategories();
        return commit("setCategories", data);
      } catch (error) {
        throw new Error(error);
      }
    },

    selectProduct({ commit }, selectedProduct) {
      return commit("setSelectedProduct", selectedProduct);
    }
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getParentCategories(state) {
      return state.categories.filter(category => category.parent === 0);
    },

    getMembresiasCorporativas(state) {
      let membresias = state.products.filter(product => {
        let categories = product.categories.map(c => c.id);
        return categories.includes(80) && categories.includes(82);
      });
      return membresias;
    },
    getTestDiscPersonal(state) {
      let membresias = state.products.filter(product => {
        let categories = product.categories.map(c => c.id);
        return categories.includes(81) && categories.includes(83);
      });
      return membresias;
    },
    getCategories(state) {
      return state.categories;
    },
    getSelectedProduct(state) {
      return state.selectedProduct;
    }
  }
};
