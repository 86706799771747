<template>
  <div>
    <div class="container-for-general-not-carousel">
      <div style="width: 100%;" class="mobile-line">
          <img
            style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
            src="../../../assets/line-horizontal.png"
            alt=""
          />
          <span class="sub-menu"> TEST PERSONAL </span>
      </div>
      <h1 class="color-white" style="font-size: 35px; padding-top: 1rem; text-align: left;">
          Adquiere <span style="color: #0198CF; ">Nuestro</span> Test DISC Personal
      </h1>
      <div style="font-size: large; padding-top: 1rem;">
          <p class="color-white" style="text-align: left;">
            Si compras tu test te obsequiamos la interpretación del mismo
            directamente de un experto.
          </p>
          <div style="margin: auto; display: flex; justify-content: flex-start;">
            <v-btn style="width: fit-content;height: 3rem; margin-top: 2rem; color: white;" color="#E700FF"> COMPRAR </v-btn>
          </div>
      </div>
    </div>
         
     
    <div  class="container-general-c" style="width: 100%;height:100vh;overflow: hidden;">
          <div  style="background-color: #001E8C;position: relative;"  class="container-carousel-item">
                     
            <div class="container-carousel-content responsive-class-carousel">
        <div>
          <img
            style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
            src="../../../assets/line-horizontal.png"
            alt=""
          />
          <span class="sub-menu"> TEST PERSONAL </span>
        </div>
        <h1 style="color: white;font-size: 3.5rem;">
          Adquiere <span class="sub-menu">Nuestro</span> Test DISC Personal
        </h1>
        <div class="carouselText">
          <p class="color-white">
            Si compras tu test te obsequiamos la interpretación del mismo
            directamente de un experto.
          </p>
          <v-btn style="width: 10rem;background-color: #E700FF;color: white;font-weight: bold;" > COMPRAR </v-btn>
        </div>
      </div>

      
      <img class="responsive-class-carousel IMG"  src="../../../assets/imagen-persona 1.png" alt="">
          </div>
        </div>
   
 
</div>
</template>

<style lang="scss">


.container-general-c {
  width: 100%;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.IMG{
  position: absolute; 
  left: 65%; 
  bottom: 0.01rem;
}
.sub-menu {
  color: #0198CF;
}

.container-carousel-item {
  width:100%;
  height: 100%;
  display: flex;
justify-content: center;
align-items: center;
align-content: center;
}

.color-white {
  color: white;
}

.title-class {
  font-size: 3rem;
  width: max-content !important
}

.carouselText p {
  width: 25rem;
}

.container-carousel-content {
  text-align: start;
  align-items: center;
  justify-content: center;
margin-right: 10rem;
  width: 40rem;
  height: 15rem !important;
  position: absolute;
  left: 10rem;
  bottom: 13rem;
  

}

@media screen and (min-width: 768px) {
  .container-for-general-not-carousel{
    display: none;
  }
}
@media screen and (min-width: 800px) and (max-width: 1250px) {
  .container-carousel-content {
 
  bottom: 10rem;
  left: 6rem;

  }
  .IMG{
    bottom:-1rem;
  }
}
@media screen and (max-width: 900px) {
  .container-carousel-content {
 
  bottom: 4.5rem;
  left: 6rem;

  }
  .IMG{
    bottom:-10rem;
  }
}
@media screen and (max-width: 768px) {
  .container-carousel-content {
 
  bottom: 4.5rem;
  left: 6rem;

  }
  .IMG{
    bottom:-12rem;
  }
}


@media screen and (max-width: 768px){
  .container-for-general-not-carousel{
    width: auto;
    height: 35rem;
    background-color: #001E8C;
    margin-top: -3rem;
    padding:1rem;
    align-content: left;
    border-bottom-left-radius: 5rem;
  }
  .responsive-class-carousel{
    display: none;
  }
  .container-general-c{
    display: none;
  }

  .mobile-line{
    align-items: left;
    text-align: left;;
    padding-top: 4rem;
  }
}


</style>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "../../../assets/carousel-1.png"
        },
        {
          src: "../../../assets/carousel-1.png"
        },
        {
          src: "../../../assets/carousel-1.png"
        },
        {
          src: "../../../assets/carousel-1.png"
        }
      ]
    };
  }
};
</script>
