import axios from "axios";
import firebaseConfig from "@/config/firebase";
import stripePromise from "@/plugins/payment.js";
// const API_URL = "https://api.stripe.com";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL,
});

const API_URL = "https://apitest.higherupcoaching.com/";

// TODO: Add domain at Client-Only Integration Stripe config

export default {
  async getOrder(orderId) {
    try {
      if (orderId) {
        const response = await axios.get(
          API_URL + `woocommerce/orders/${orderId}`
        );
        return response;
      } else {
        console.log("Id not foud for this order or not put an order id");
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateOrder(order) {
    try {
      if (order) {
        const response = await axios.put(API_URL + "woocommerce/orders", order);
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async createOrder(order) {
    try {
      if (order) {
        const response = await axios.post(
          API_URL + "woocommerce/orders",
          order
        );
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async finishPayment(data) {
    try {
      if (data) {
        const response = await instance.post(
          "/shop/finish-membership-payment",
          data
        );
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async processPayment(params) {
    const { priceId, customerId, type, quantity } = params;
    const session = await instance.post("/shop/start-checkout", {
      priceId,
      customerId,
      type,
      quantity,
    });
    stripePromise.then(async (stripe) => {
      stripe
        .redirectToCheckout({
          sessionId: session.data.id,
        })
        .then((response) => {
          console.log("Checkout Success");
          console.log(response);
        })
        .catch((error) => {
          console.log("Checkout Error");
          console.log(error);
        });
    });
  },
  async createCheckoutSession(body, orderId) {
    try {
      if (body) {
        let response = await axios.post(API_URL + `stripe/embedded/${orderId}`, body);
        return response.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async createPaymentElement(body, orderId) {
    try {
      if (body) {
        
        let response = await axios.post(API_URL + `stripe/${orderId}`, body);
        return response.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async getCheckoutStatus(id) {
    try {
      if (id) {
        let response = await axios.get(API_URL + `stripe/status/${id}`);
        return response.data;
      } else {
        console.error("id not found, please enter id param");
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async getPaymentMethods() {
    try {
      let data = await axios.get(API_URL + "woocommerce/payments/methods");
      return data;
    } catch (error) {
      throw new Error(error);
    }
  },
};
