<template>
  <div class="container-general-for-corp-test-content">
    <div class="container-banner-indi content">
      <div style="margin-top: 2rem;">
        <img style="padding: 0.3rem 0.3rem 0.3rem 0.3rem" src="../../../assets/line-horizontal.png" alt="" />
        <span class="sub-menu"> Test </span>
      </div>
      <h1 v-html="getTestDiscPersonal[0].name" class="mt-8 color-white title-class">
      </h1>
      <!-- <p v-html="getTestDiscPersonal[0].description" class="p-for-description-indi-test color-white"
        style="font-family: montserrat; font-weight: 500; color: white !important;">
    </p> -->
      <div class="test-disc-personal-container">
        <TestDiscPersonal />
      </div>
    </div>
  </div>
</template>

<style>

@media screen and (max-width:768px){
  .test-disc-personal-container{
    margin: auto;
    justify-content: flex-start;
    display: flex;
    margin-left: -3rem !important;
  }
  .container-general-for-corp-test-content{
    margin-top: -3rem !important;

  }
}


.container-general-for-corp-test-content{
  display:flex;
  margin: auto;
  justify-content:center;
  margin-top: 0;
  @media screen and (min-width: 768px){
    height: 100vh;
  }
}

.p-for-description-indi-test {
  color: white !important;
}

.test-disc-personal-container {
  margin: auto;
  display: flex;
}

.sub-menu {
  color: #658dc0;
  font-style: italic;
}

.container-banner-indi {
  width: 100% !important;
  background-size: cover;
  background-color: #001e8c;
  border-bottom-left-radius: 8rem;
}

.color-white {
  color: white !important;
}

.title-class {
  font-size: 3rem;
  display: flex;
  gap: 1.5rem;
}

.content {
  margin-top: -2rem;
  margin-bottom: 2rem;
  display: flex;
  text-align: start;
  flex-direction: column;
  padding-left: 10rem;
  align-items: flex-start;
  justify-content: center;
  height: auto !important;
  width: 100%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import TestDiscPersonal from './TestDiscPersonal.vue';

export default {
  components: {
    TestDiscPersonal
  },
  computed: {
    ...mapGetters(["getTestDiscPersonal"])
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth",
        });
      }
    },
  },
  async mounted() {
    await Promise.all([this.updateProducts()]);
    await this.getTestDiscPersonal;
    console.log(this.getTestDiscPersonal, "Testinggggggg");
  }
};
</script>
