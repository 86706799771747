<template>
  <v-container class="pa-0" fluid>
    <Banner />
    <NuestraEmpresaUs />
    <BannerNumerico />
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import Banner from "./components/Banner.vue";
import NuestraEmpresaUs from "./components/NuestraEmpresaUs.vue";
import BannerNumerico from "./components/BannerNumerico.vue";

export default defineComponent({
  components: {
    Banner,
    NuestraEmpresaUs,
    BannerNumerico,
  }
});
</script>
