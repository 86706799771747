<template id="body">
  <div class="container-slide-background">
    <vueper-slides
      class="no-shadow"
      :visible-slides="6"
      :arrows="true"
      :slide-ratio="1 / 4"
      :gap="3"
      :dragging-distance="70"
      :style="'height:10rem'"
    >
      <vueper-slide
        class="slide-item"
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
      />
    </vueper-slides>
  </div>
</template>

<!-- ./../../../assets/huc-logo-carousel.svg -->
<script>
// In your Vue.js component.
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/huc-logo-carousel.svg")
        },
        {
          image: require("@/assets/huc-logo-carousel.svg")
        },
        {
          image: require("@/assets/huc-logo-carousel.svg")
        },
        {
          image: require("@/assets/huc-logo-carousel.svg")
        },
        {
          image: require("@/assets/huc-logo-carousel.svg")
        },
        {
          image: require("@/assets/huc-logo-carousel.svg")
        }
      ]
    };
  }
};
</script>
<style>
.slide-item {
  width: 5rem;
  height: 10rem;
}
.container-slide-background {
  background-color: white; 
}

</style>
