<template>
  <v-card :loading="loading" class=" v-card-for-order-sumary" max-width="374">
    <v-container>
      <v-row>
        <v-col>
          <h3 style="color: #0198CF" class="span-payment-title">Orden</h3>
        </v-col>
        <v-col>
          <span style="color: #0198CF" class="span-payment-number"> {{ getOrderInfo.number }} </span>
        </v-col>
      </v-row>
    </v-container>

    <div class="form-img-icon">
      <img src="./../../../assets/checkout-form.png" alt="" />
    </div>
    <v-card-text class="line_and_text_alight">
      <div>
        <img
          style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
          src="../../../assets/line-horizontal.png"
          alt=""
        />
      </div>
      <span> {{ getSelectedProduct.title }} </span>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <h3 class="test-disc-personal-title">{{ getSelectedProduct.name }}</h3>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <span class="span-title"> Precio </span>
          </v-col>
          <v-col>
            <span class="span-price"> {{ getSelectedProduct.price }} USD </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="#0000FF" style="color: white" class="mr-4" @click="comprar">Comprar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
.form-img-icon {
  display: flex;
  margin: 1rem;
  justify-content: flex-start;
}

.line_and_text_alight {
  display: flex;
  justify-content: flex-start;
  margin: auto;
}

.test_subtitle {
  display: flex;
  justify-content: flex-start;
  margin: 1rem;
}

.span-title {
  text-align: start;
  margin: auto;
  justify-content: flex-start;
  display: flex;
}

.span-payment-title {
  text-align: start;
  margin: auto;
  justify-content: flex-start;
  display: flex;
}

.span-payment-number {
  text-align: end;
  margin: auto;
  justify-content: end;
  display: flex;
}

.span-price {
  text-align: end;
  margin: auto;
  justify-content: end;
  display: flex;
}

.test-disc-personal-title {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  margin: 2rem;
}

@media screen and (max-width:768px) {
  .v-card-for-order-sumary{
    min-width: 20rem;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    loading: false,
    selection: 1,
  }),
  methods: {
    ...mapActions(["createOrder"]),
    volver() {
      // Emitir un evento personalizado para que Checkout.vue valide el formulario
      this.$emit("validateForm");
    },
    comprar() {
      // Emitir un evento personalizado para que Checkout.vue valide el formulario
      this.$emit("comprar");
    },
  },
  created() {},
  computed: {
    ...mapGetters(["getSelectedProduct", "getOrderInfo"]),
  },
  async mounted() {
    await this.createOrder({
      line_items: [
        {
          product_id: `${this.getSelectedProduct.id}`,
          quantity: 1,
        },
      ],
      set_paid: false,
      status: "pending",
    });
    console.log(this.getOrderInfo)
  },
};
</script>
