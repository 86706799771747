<template>
  <div>
    <div class="servicios-especializados-mobile">
      <div style="text-align: left; padding-left: 2rem; padding-top: 2rem;">
        <img style="padding: 0.3rem 0.9rem 0.3rem 0.3rem; "
          src="../../../assets/line-horizontal.png"
          alt=""
        />
        <span style="color: #0198CF; font-style: oblique; " > SERVICIOS </span>
      </div>
      <div>
        <h1 style="color: #0000FF; text-align: left;padding-left: 2rem; padding-top: 1rem;">Servicios </h1>
        <h1 style="color: #0000FF; text-align: left;padding-left: 2rem">Especializados </h1>
        <p style="color: #686868; text-align: left; padding-left: 2rem; padding-bottom: 2rem;">
          Según sea tu objetivo adaptamos nuestros servicios para que logres el éxito en todos tus proyectos.
        </p>
      </div>

      <div class="coachings-mobile-display">
        <div
          v-for="(coaching, index) in getParentCategories"
          :key="index"
          class="coaching-item-mobile"
          style="cursor:pointer"
        >
          <div class="d-flex container-title-corporatvo-mobile">
            <img
              style="width: 4rem; height: 4rem; margin-right: 5%;"
              :src="coachings[index].iconSrc"
              alt=""
            />
            <h2 class="title-corporativo-mobile">
              {{ coaching.name }}
            </h2>
          </div>
          <div class="line-mobile"></div>
          <div class="container-content-1-mobile">
            <p class="container-p-mobile">
              {{ coaching.description }}
            </p>
            <div class="container-button-center-mobile">
              <v-btn style="width: 80%" @click="handleButtonClick(coaching.type)">
                SABER MAS
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="servicios-especializados">
      <div class="coachings">
        <div
          v-for="(coaching, index) in getParentCategories"
          :key="index"
          class="coaching-item"
          style="cursor:pointer"
        >
          <div class="d-flex container-title-corporatvo">
            <img
              style="width: 4rem; height: 4rem"
              :src="coachings[index].iconSrc"
              alt=""
            />
            <h2 class="title-corporativo">
              {{ coaching.name }}
            </h2>
          </div>
          <div class="line"></div>
          <div class="container-content-1">
            <p class="container-p">
              {{ coaching.description }}
            </p>
            <div class="container-button-center">
              <v-btn style="width: 80%" @click="handleButtonClick(coaching.type)">
                SABER MAS
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="text-general-content">
        <div>
          <img
            src="../../../assets/line-horizontal.png"
            alt=""
          />
          <span style="color: #0198CF"> SERVICIOS </span>
        </div>
        <div>
          <h1 style="color: #0000FF">Servicios Especializados</h1>
          <p style="color: #686868">
            Según sea tu objetivo adaptamos nuestros servicios para que logres el
            éxito en todos tus proyectos.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.coachings-mobile-display{
  flex-wrap: wrap;
}


.container-general {
  width: 100% !important;
}

.coaching-p {
  width: auto;
}
.container-content-1 {
  justify-content: center;
  margin: auto;
  text-align: start;

  p {
    width: auto;
    margin: auto;
    margin-left: 2rem;
    text-align: start;
    margin-bottom: 1rem;
  }
}

.container-button-center {
  justify-content: center;
  display: flex;
  margin: auto;
}

.coachings {
  display: flex;
}

.servicios-especializados {
  height: 30rem;
  width: 100%;
  background-color: #F6F6F6;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.text-general-content {
  text-align: start;
  justify-content: start;
  margin: 1rem;
  margin-bottom: 5rem !important;
}

.coaching-item {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
  padding: 1rem;
  margin: 1rem;
  width: 18rem;
  height: 18rem;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 0 20px 0 20px;
  border: 1px solid white;

  .container-button-center button {
    background-color: #0000FF !important;
    color: white;
  }
  .title-corporativo {
    margin-left: 1rem;
    margin-top: 1rem;
    text-align: start;
    color:#0000FF;
  }

  .container-title-corporatvo {
    margin-left: 2rem;
  }

  .line {
    border-bottom: 1px solid black;
    width: 80%;
    margin: auto;
    justify-content: center;
    display: flex;
    padding: auto;
    margin-top: 1rem;
  }
  .container-p {
    color: #686868;
  }
  &:hover {
    background-color: white;
    .line {
      border-bottom: 1px solid black;
    }
    .container-button-center button {
      background-color: #0000FF !important;
      color: white;
    }

    .container-p {
      color: #686868;
    }
    .title-corporativo {
      color:#0000FF;
    }
    width: 20rem;
    height: 20rem;
  }
}

.coaching-item-mobile{
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
  padding: 1rem;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 16rem;
  height: 16rem;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 0 20px 0 20px;
  border: 1px solid white;
  .container-button-center-mobile button {
    background-color: #0000FF !important;
    color: white;
  }
  .title-corporativo-mobile {
    text-align: start;
    color:#0000FF;
  }

  .container-title-corporatvo-mobile {
    margin-left: 2rem;
  }

  .line-mobile {
    border-bottom: 1px solid black;
    width: 80%;
    margin: auto;
    justify-content: center;
    display: flex;
    padding: auto;
    margin-top: 1rem;
  }
  .container-p-mobile {
    color: #686868;
  }
}

.horizontal-line-for-faqs {
  width: 50px;
  height: 0px;
  border-top: 2px solid #0198CF;
  margin-right: 10px;
  /* Espacio entre la línea y el subtítulo */
}

@media screen and (min-width: 768px){
  .servicios-especializados-mobile{
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .servicios-especializados{
    display: none;
  }
  .servicios-especializados-mobile{
    background-color: #F6F6F6;
    width: 100%;
    height: 60rem;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      coach: [],
      coachings: [
        {
          type: "corporativo",
          title: "COACHING CORPORATIVO",
          img:"../../../assets/corp-icon-mem.png",
          iconSrc: require("../../../assets/corp-icon-mem.png"),
          content: "Expande tus competencias organizacionales."
        },
        {
          type: "personal",
          title: "COACHING PERSONAL",
          img:"../../../assets/coaching-personal-new-icon.svg",
          iconSrc: require("../../../assets/coaching-personal-new-icon.svg"),
          content: "Recibe el test, resultados y plan de desarrollo."
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getParentCategories"])
  },
  methods: {
    ...mapActions(["updateCategories"]),
    handleHover(index) {
      this.isHovered = index;
    },
    handleButtonClick(type) {
      console.log(`Botón de ${type} clickeado`);
    }
  },
  async mounted() {
    await this.updateCategories();
    console.log(this.getParentCategories);
  }
};
</script>
