<template>
  <v-app id="ovaldisc">
    <div
      class="container-display-for-desktop-responsive"
      style="
        min-height: 2rem;
        background-color: black;
        align-items: center;
        width: 100%;
      "
    >
      <span
        class="comience-su-viaje-de-liderazgo-atrevido"
        style="
          margin: auto;
          display: flex;
          justify-content: flex-start;
          color: white;
          align-items: center;
          position: relative;
          top: 0.5rem;
          left: 2rem;
        "
      >
        Comience su viaje de liderazgo atrevido
      </span>
    </div>
    <v-app-bar :elevation="24" fixed app class="responsive-mobile-app-bar">
      <template v-slot:prepend>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </template>

      <v-app-bar-title>
        <img
          class="logo-for-mobile-responsive-horizontal"
          src="./assets/higher-horizontal-logo.png"
          alt=""
        />
      </v-app-bar-title>
      <div style="margin-left: auto">
        <v-menu bottom v-if="isUserAuthenticated" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="background: transparent"
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 17.4375C20.3135 17.4375 24.2188 13.5322 24.2188 8.71875C24.2188 3.90527 20.3135 0 15.5 0C10.6865 0 6.78125 3.90527 6.78125 8.71875C6.78125 13.5322 10.6865 17.4375 15.5 17.4375ZM23.25 19.375H19.9139C18.5697 19.9926 17.0742 20.3438 15.5 20.3438C13.9258 20.3438 12.4363 19.9926 11.0861 19.375H7.75C3.46934 19.375 0 22.8443 0 27.125V28.0938C0 29.6982 1.30176 31 2.90625 31H28.0938C29.6982 31 31 29.6982 31 28.0938V27.125C31 22.8443 27.5307 19.375 23.25 19.375Z"
                  fill="#FFFFFF"
                />
              </svg>
            </v-btn>
          </template>
          <v-list class="o-opacity">
            <v-list-item
              v-for="(menu, i) in appMenuItems"
              :key="i"
              @click="menu.handler"
            >
              <v-list-item-icon v-if="menu.icon">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menu.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn
              style="background: transparent"
              icon
              color="primary"
              v-on="on"
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M4 18L20 18"
                    stroke="#f9f6f6"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M4 12L20 12"
                    stroke="#f9f6f6"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M4 6L20 6"
                    stroke="#f9f6f6"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                </g>
              </svg>
            </v-btn>
          </template>
          <v-list class="o-opacity">
            <v-list-item @click="navigateTo('/')">
              <v-list-item-title>INICIO</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/sobre-nosotros')">
              <v-list-item-title>CONÓCENOS</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/servicios/corporativos')">
              <v-list-item-title>MEMBRESÍAS DISC</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/servicios/individuales')">
              <v-list-item-title>APP DISC</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-app-bar
      class="container-app-bar-styling"
      app
      fixed
      shrink-on-scroll
      scroll-threshold="250"
      prominent
      elevation="0"
    >
      <v-btn icon color="secondary" v-if="showMenu" @click="toggleReportMenu()">
        <v-icon>fas fa-list</v-icon>
      </v-btn>
      <v-app-bar-title class="navbarTitle"></v-app-bar-title>

      <div
        class="tabs-class"
        style="justify-content: flex-end; display: flex; margin: auto"
      >
        <v-tabs
          style="
            justify-content: flex-end;
            display: flex;
            margin: auto;
            margin-right: 1rem;
          "
          :center-active="true"
          align-tabs="center"
          color="black"
        >
          <v-tab
            :center-active="true"
            align-tabs="center"
            @click="navigateTo('/')"
            class="tabs-custom"
            :value="1"
          >
            <p class="tab-text">INICIO</p>
          </v-tab>

          <v-tab
            :center-active="true"
            align-tabs="center"
            @click="navigateTo('/sobre-nosotros')"
            class="tabs-custom"
            :value="2"
          >
            <p class="tab-text">CONÓCENOS</p>
          </v-tab>
          <v-tab
            :center-active="true"
            align-tabs="center"
            @click="navigateTo('/servicios/corporativos')"
            class="tabs-custom"
            :value="2"
          >
            <p class="tab-text">MEMBRESÍAS DISC</p>
          </v-tab>
          <v-tab
            :center-active="true"
            align-tabs="center"
            @click="navigateTo('/servicios/individuales')"
            class="tabs-custom"
            :value="2"
          >
            <p class="tab-text">APP DISC</p>
          </v-tab>
          <v-tab
            :center-active="true"
            align-tabs="center"
            @click="navigateTo('/contacto')"
            class="tabs-custom"
            :value="2"
          >
            <p class="tab-text">CONTÁCTANOS</p>
          </v-tab>
        </v-tabs>
      </div>

      <div
        id="secondMenu"
        style="
          justify-content: center;
          align-items: center;
          display: flex;
          margin: auto;
        "
      >
        <v-menu left bottom v-if="isUserAuthenticated" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="background: transparent"
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 17.4375C20.3135 17.4375 24.2188 13.5322 24.2188 8.71875C24.2188 3.90527 20.3135 0 15.5 0C10.6865 0 6.78125 3.90527 6.78125 8.71875C6.78125 13.5322 10.6865 17.4375 15.5 17.4375ZM23.25 19.375H19.9139C18.5697 19.9926 17.0742 20.3438 15.5 20.3438C13.9258 20.3438 12.4363 19.9926 11.0861 19.375H7.75C3.46934 19.375 0 22.8443 0 27.125V28.0938C0 29.6982 1.30176 31 2.90625 31H28.0938C29.6982 31 31 29.6982 31 28.0938V27.125C31 22.8443 27.5307 19.375 23.25 19.375Z"
                  fill="#304A6F"
                />
              </svg>
            </v-btn>
          </template>
          <v-list class="o-opacity">
            <v-list-item
              v-for="(menu, i) in appMenuItems"
              :key="i"
              @click="menu.handler"
            >
              <v-list-item-icon v-if="menu.icon">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menu.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu left bottom offset-y v-if="!isUserAuthenticated">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="white" v-bind="attrs" v-on="on">
              <img src="./assets/user-icon.svg" alt="" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="navigateToLogin()">
              <v-list-item-title>Inicio de sesión</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateToRegister()">
              <v-list-item-title>Registro</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-container fluid class="ma-0 pa-0">
      <v-row class="ma-0">
        <!-- <v-col md="1" class="hidden-sm-and-down pa-0"></v-col> -->
        <v-col class="pa-0 text-center" cols="12">
          <router-view
            @open-dialog="openDialog"
            @set-floating-action="setFloatingAction"
          ></router-view>
        </v-col>
        <!-- <v-col md="1" class="hidden-sm-and-down pa-0"></v-col> -->
      </v-row>
    </v-container>

    <v-dialog width="200" v-model="processingRequest" persistent>
      <v-card>
        <v-card-title class="text-h6"> Procesando... </v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <o-message-dialog ref="messageDialog" />
    <!-- footer container -->
    <!-- footer container -->

    <div
      style="padding-left: 2rem; background-color: #eeeeee"
      class="segment-for-footer-mobile"
      fluid
    >
      <div class="d-flex element-info">
        <div>
          <img class="icons-width" src="./assets/Ubicacion.png" alt="" />
        </div>
        <div>
          <div class="color-infos">Ubicacion</div>
          <div>Direccion aqui</div>
        </div>
      </div>
      <div class="d-flex element-info">
        <div>
          <img class="icons-width" src="./assets/Telefono.png" alt="" />
        </div>
        <div>
          <div class="color-infos">Telefono</div>
          <div>000 000 0000</div>
        </div>
      </div>
      <div class="d-flex element-info">
        <div>
          <img class="icons-width" src="./assets/Correo.png" alt="" />
        </div>
        <div>
          <div class="color-infos">Correo</div>
          <div>correo@ejemplo.com</div>
        </div>
      </div>
    </div>
    <div class="footer-class-container-mobile">
      <div class="logo-and-description-mobile">
        <img
          style="padding-top: 6rem; padding-left: 4rem; height: 80%; width: 80%"
          src="./assets/logo3.svg"
          alt=""
        />
        <div>
          <p
            style="
              text-align: center;
              color: #eeeeee;
              padding-top: 2rem;
              font-size: small;
              padding-left: 2rem;
              padding-right: 2rem;
            "
          >
            Higher Up Coaching es una empresa líder en coaching dedicada a
            potenciar a individuos y organizaciones para alcanzar su máximo
            potencial
          </p>
        </div>
        <div style="display: flex; justify-content: center">
          <div>
            <img
              style="height: 2rem"
              class="icon-network"
              src="./assets/facebook.png"
              alt=""
            />
            <img
              style="height: 2rem"
              class="icon-network"
              src="./assets/instagram.png"
              alt=""
            />
            <img
              style="height: 2rem"
              class="icon-network"
              src="./assets/whatsapp.png"
              alt=""
            />
          </div>
        </div>
        <ul type="none" class="list-ul" style="padding-top: 2rem">
          <h3>Paginas</h3>
          <li @click="navigateTo('/')">
            <img src="./assets/right-row.png" alt="" /> Inicio
          </li>
          <li @click="navigateTo('/sobre-nosotros')">
            <img src="./assets/right-row.png" alt="" /> Sobre Nosotros
          </li>
          <li @click="navigateTo('/contacto')">
            <img src="./assets/right-row.png" alt="" /> Contáctanos
          </li>
          <li @click="navigateTo('/tyc')">
            <img src="./assets/right-row.png" alt="" /> Términos y Condiciones
          </li>
          <li @click="navigateTo('/politicas')">
            <img src="./assets/right-row.png" alt="" /> Políticas de Privacidad
          </li>
          <li @click="navigateTo('/cookies')">
            <img src="./assets/right-row.png" alt="" /> Políticas de Cookies
          </li>
        </ul>

        <ul type="none" class="list-ul">
          <h3>Servicios</h3>
          <li @click="navigateTo('/servicios/corporativos')">
            <img src="./assets/right-row.png" alt="" /> Membresías DISC
          </li>
          <li @click="navigateTo('/servicios/individuales')">
            <img src="./assets/right-row.png" alt="" /> APP DISC
          </li>
        </ul>
        <div>
          <ul
            style="text-align: center; padding-top: 3rem; padding-bottom: 3rem"
            type="none"
          >
            <li>
              <p style="font-size: small; color: #eeeeee; padding-right: 1rem">
                Todos los derechos reservados | Higher Up Coaching 2024
              </p>
            </li>
            <li style="padding-top: 2rem">
              <p
                style="font-size: small; color: #eeeeee; margin: 0%"
                @click="navigateTo('/politicas')"
              >
                Politicas de privacidad
              </p>
            </li>
            <li>
              <p
                style="font-size: small; color: #eeeeee; margin: 0%"
                @click="navigateTo('/tyc')"
              >
                Terminos y Condiciones
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="bg-grey-lighten-1 flex-column footer-class-container">
      <div class="container-icons-for-footer">
        <div
          class="d-flex justify-end background-white segment-for-footer"
          fluid
        >
          <div class="d-flex element-info">
            <div>
              <img class="icons-width" src="./assets/Ubicacion.png" alt="" />
            </div>
            <div>
              <div class="color-infos">Ubicacion</div>
              <div>Direccion aqui</div>
            </div>
          </div>
          <div class="d-flex element-info">
            <div>
              <img class="icons-width" src="./assets/Telefono.png" alt="" />
            </div>
            <div>
              <div class="color-infos">Telefono</div>
              <div>000 000 0000</div>
            </div>
          </div>
          <div class="d-flex element-info">
            <div>
              <img class="icons-width" src="./assets/Correo.png" alt="" />
            </div>
            <div>
              <div class="color-infos">Correo</div>
              <div>correo@ejemplo.com</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-background">
        <div class="display-data-footer-icons">
          <div class="container-general-info">
            <div style="margin-top: 3rem; margin-right: 5rem">
              <img src="./assets/higher-horizontal-logo.png" alt="" />
              <p style="width: 15rem; color: white">
                Higher Up Coaching es una empresa líder en coaching dedicada a
                potenciar a individuos y organizaciones para alcanzar su máximo
                potencial
              </p>
              <div class="icon-network-container">
                <img class="icon-network" src="./assets/facebook.png" alt="" />
                <img class="icon-network" src="./assets/instagram.png" alt="" />
                <img class="icon-network" src="./assets/whatsapp.png" alt="" />
              </div>
            </div>
            <div
              class="container-ul"
              style="margin-top: 3rem; margin-right: 5rem; margin-left: 5rem"
            >
              <ul type="none" class="list-ul">
                <h3>Paginas</h3>
                <li @click="navigateTo('/')">
                  <img src="./assets/right-row.png" alt="" /> Inicio
                </li>
                <li @click="navigateTo('/sobre-nosotros')">
                  <img src="./assets/right-row.png" alt="" /> Sobre Nosotros
                </li>
                <li @click="navigateTo('/contacto')">
                  <img src="./assets/right-row.png" alt="" /> Contáctanos
                </li>
                <li @click="navigateTo('/tyc')">
                  <img src="./assets/right-row.png" alt="" /> Términos y
                  Condiciones
                </li>
                <li @click="navigateTo('/politicas')">
                  <img src="./assets/right-row.png" alt="" /> Políticas de
                  Privacidad
                </li>
                <li @click="navigateTo('/cookies')">
                  <img src="./assets/right-row.png" alt="" /> Políticas de
                  Cookies
                </li>
              </ul>
            </div>
            <div class="container-ul">
              <ul type="none" class="list-ul">
                <h3>Servicios</h3>
                <li @click="navigateTo('/servicios/corporativos')">
                  <img src="./assets/right-row.png" alt="" /> Corporativos
                </li>
                <li @click="navigateTo('/servicios/individuales')">
                  <img src="./assets/right-row.png" alt="" /> Individuales
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-flex terms-and-conditions-class">
          <div class="d-flex politics">
            <p
              style="font-size: small; margin-top: 0.6rem; margin-right: 45rem"
              class="copy"
            >
              Todos los derechos reservados | Higher Up Coaching 2024
            </p>
            <p
              style="font-size: small; margin-top: 0.5rem"
              @click="navigateTo('/politicas')"
            >
              Politicas de privacidad
            </p>
            <p
              style="font-size: small; margin-top: 0.5rem; margin-left: 1rem"
              @click="navigateTo('/tyc')"
            >
              Terminos y Condiciones
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      processingRequest: false,
      /*       appMenuItems: [
        {
          label: "Perfil",
          icon: "mdi-account",
          handler: this.navigateToProfile,
        },
        {
          label: "Configuración",
          icon: "mdi-settings",
          handler: this.navigateToSettings,
        },
        { label: "Cerrar sesión", icon: "mdi-logout", handler: this.logout },
      ], */
      isUserAuthenticated: true,
    };
  },

  computed: {
    appMenuItems() {
      return [
        {
          label: "Perfil",
          icon: "mdi-account",
          handler: this.navigateToProfile,
        },
        {
          label: "Configuración",
          icon: "mdi-settings",
          handler: this.navigateToSettings,
        },
        {
          label: "Cerrar sesión",
          icon: "mdi-logout",
          handler: this.logout,
        },
      ];
    },
  },
  methods: {
    toggleReportMenu() {
      this.showMenu = !this.showMenu;
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    navigateToProfile() {
      this.navigateTo("/profile");
    },
    navigateToSettings() {
      this.navigateTo("/settings");
    },
    navigateToLogin() {
      this.navigateTo("/login");
    },
    navigateToRegister() {
      this.navigateTo("/register");
    },
    logout() {
      // Implement your logout functionality here
      console.log("Logging out...");
    },
    openDialog() {
      this.$refs.messageDialog.open();
    },
    setFloatingAction(action) {
      this.$refs.floatingActionButton.setAction(action);
    },
  },
};
</script>

<style lang="scss">
.comience-su-viaje-de-liderazgo-atrevido {
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.94px;
  text-align: left;
}

.terms-and-conditions-class {
  position: absolute;
  bottom: 0rem;
}

.display-data-footer-icons {
  margin: auto;
  position: absolute;
  right: 10rem;
}

.container-icons-for-footer {
  position: relative;
  bottom: 14.5rem;
}

.segment-for-footer-mobile {
  height: 15rem;
  background-color: white;
}

.segment-for-footer {
  margin-top: 13rem;
}

@media screen and (max-width: 768px) {
  .container-display-for-desktop-responsive {
    display: none;
  }
}

.navbarTitle {
  background-image: url("assets/logo3.svg");
  height: 100%;
  width: 10rem;
}

.v-toolbar {
  background-color: transparent !important;
}

.v-app-bar {
  & .v-app-bar--is-scrolled {
    background-color: transparent;
  }
}

.headerLogo {
  padding: 5px;
  height: 100%;
}

.tabs-custom {
  padding: 0 4px;
  /* Reduce el padding horizontal a 4 píxeles */
  min-width: 0;
  /* Permite que los tabs se ajusten al contenido */
}

.tab-text {
  margin: 0;
  /* Elimina el margen predeterminado */
  font-size: 12px;
  /* Ajusta el tamaño de fuente según tus necesidades */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}

.tabs-class {
  width: 60%;
  background-color: transparent !important;
}

.politics {
  margin: auto;
  justify-content: flex-end;
  display: flex;
  margin-top: 2.8rem !important;

  p {
    cursor: pointer;
    color: white !important;
  }

  p:hover {
    text-decoration: underline;
  }
}

.copy {
  margin-left: 8rem;
  margin-top: 3rem;
  color: white;
}

.element-info {
  margin: auto;
  margin-top: 1.5rem;
}

.color-infos {
  color: #5e5e5e;
}

.icons-width {
  margin-right: 1rem;
  width: 2.5rem;
}

.container-ul {
  margin-top: 4rem !important;
}

.list-ul {
  color: white;

  li {
    cursor: pointer;
    margin: 9px;
  }

  li:hover {
    text-decoration: underline;
  }
}

.container-general-info {
  margin: auto;
  display: flex;
  margin-right: 10rem;
}

.card-component {
  margin-left: 10rem;
  bottom: -1.45rem;
}

.background-white {
  background-color: white;
  height: 6rem;
}

.container-background {
  background-color: #001e8c;
  margin-top: -15rem;
  height: 25rem;
  width: 100%;
}

.white-background {
  background-color: #ffffff !important;
}

.o-footer > div {
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
}

.icon-network {
  margin: 1rem 1rem 0rem 0rem;
  cursor: pointer;
}

.icon-network-container {
  justify-content: flex-start;
  margin: auto;
  display: flex;
  padding-left: 0%;
}

.o-opacity {
  opacity: 0.9;
}

.blackText {
  color: black;
}

.footer-form {
  margin-top: -119px;
}

.o-menu-item {
  white-space: normal;
}

.v-card__title {
  word-break: normal !important;
}

@media screen {
  .no-screen {
    display: none !important;
  }

  .theme--light.v-application {
    /* background-color: #37526d !important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
  }

  .od-mt-16 {
    margin-top: 16px !important;
  }
}

@media print {
  .no-print,
  nav,
  header,
  footer {
    display: none !important;
  }

  main {
    padding-left: 0rem !important;
  }

  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
}

.v-messages__message {
  font-weight: 600;
}

.v-slide-group__content,
.v-tabs-bar__content,
.v-toolbar__content {
  background-color: #001e8c;
}

p {
  color: black;
  padding-top: 1rem;
}

.transparent-background {
  background-color: transparent !important;
}

.white-background {
  background-color: white !important;
}

.tabs-custom {
  padding: 0 4px;
  min-width: 0;
}

.v-tabs-slider {
  background-color: white;
}

.tab-text {
  margin: 0;
  font-size: 10px;
  color: white;
  white-space: nowrap;
}

/* Asegúrate de que los elementos dentro de v-tabs sean transparentes cuando sea necesario */
.transparent-background >>> .v-tabs-bar,
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

.transparent-background >>> .v-tab {
  background-color: transparent !important;
}

@media screen and (min-width: 768px) {
  .responsive-mobile-app-bar {
    display: none;
  }
  .footer-class-container-mobile {
    display: none !important;
  }
  .segment-for-footer-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .footer-class-container {
    display: none;
  }

  .container-display-for-desktop-responsive {
    display: none;
  }

  .container-app-bar-styling {
    display: none;
  }

  .tabs-class {
    display: none !important;
  }

  .navbarTitle {
    height: 80%;
    width: 5rem;
  }

  .class-for-responsive1 {
    display: none;
  }
}

.logo-for-mobile-responsive-horizontal {
  width: 5rem;
  height: 2.5rem;
}

.footer-class-container-mobile {
  justify-content: center;
  margin: auto;
  background-color: #001e8c;
}
</style>
