<template>
  <v-menu left bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon :color="color" v-bind="attrs" v-on="on">
        <v-icon small>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list class="o-opacity">
      <v-list-item v-for="(item, i) in actions" :key="i" @click="item.handler">
        <v-list-item-icon v-if="item.icon" class="mr-2">
          <v-icon small>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item>
      <slot />
    </v-list>
  </v-menu>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: false,
      default: "fas fa-bars"
    },
    actions: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: "#000000DE"
    }
  }
});
</script>

<style></style>
