<template>
  <div class="container-general-p">
    <div
      class="mobile-preguntas-frecuentes-for-responsive"
      style="padding-top: 3rem"
    >
      <div class="segment-for-freq-quest-mobile">
        <div class="contianer-segment-for-freq-quest-mobile" style="">
          <div class="container-faqs-for-sub-title-mobile">
            <img
              style="padding: 0.3rem 0.9rem 0.3rem 0.3rem"
              src="../../../assets/line-horizontal.png"
              alt=""
            />
            <span class="sub-title-faqs-mobile">FAQ´s</span>
          </div>
          <div
            class="container-title-preguntas-frecuentes-mobile"
            style="text-align: left; padding-left: 1rem; padding-bottom: 1rem"
          >
            <span class="title-preguntas-frecuentes-mobile">
              Preguntas Frecuentes
            </span>
          </div>
          <div>
            <div>
              <div class="expansion-panel-acordeon-mobile">
                <div class="container-acordion-mobile-items">
                  <div class="accordion-mobile">
                    <div
                      v-for="(item, index) in items"
                      :key="index"
                      class="accordion-item-mobile"
                    >
                      <h2
                        class="accordion-header-mobile"
                        @click="toggle(index)"
                      >
                        {{ item.title }}
                      </h2>
                      <div
                        class="accordion-content-mobile"
                        :style="{
                          display: expandedIndex === index ? 'block' : 'none',
                        }"
                      >
                        {{ item.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-faq-form-preguntas-frecuentes-mobile">
          <div class="container-form-preguntas-frecuentes-mobile">
            <div style="display: flex; margin: auto; margin-top: 1rem">
              <input
                style="width: 100%; position: relative"
                class="custom-input-mobile"
                v-model="message.nombre"
                placeholder="Nombre"
              />
              <input
                style="width: 100%; position: relative"
                class="custom-input-mobile"
                v-model="message.apellido"
                placeholder="Apellido"
              />
            </div>
            <div style="display: flex; margin: auto">
              <input
                style="width: 100%; position: relative; bottom: 1rem"
                class="custom-input-mobile"
                v-model="message.correo"
                placeholder="Correo electrónico"
              />
            </div>
            <div style="display: flex; margin: auto">
              <input
                style="
                  width: 100%;
                  height: 6rem;
                  position: relative;
                  padding-bottom: 5rem;
                "
                class="custom-input-mobile"
                v-model="message.mensaje"
                placeholder="Mensaje"
              />
            </div>
            <div style="justify-content: center; padding-bottom: 3rem">
              <v-btn color="#0000FF" style="color: white; width: 88%">
                Enviar Pregunta
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="pop-preguntas-freq">
      <div class="segment-for-freq-quest">
        <div style="margin: 2rem 0rem 0rem 3rem">
          <div class="container-faqs-for-sub-title">
            <div class="horizontal-line-for-faqs"></div>
            <div class="sub-title-faqs">FAQ´s</div>
          </div>
          <div class="container-title-preguntas-frecuentes">
            <span class="title-preguntas-frecuentes">
              Preguntas Frecuentes
            </span>
          </div>
          <div>
            <div>
              <div class="expansion-panel-acordeon">
                <div class="accordion">
                  <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="accordion-item"
                  >
                    <h2 class="accordion-header" @click="toggle(index)">
                      {{ item.title }}
                    </h2>
                    <div
                      class="accordion-content"
                      :style="{
                        display: expandedIndex === index ? 'block' : 'none',
                      }"
                    >
                      {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-faq-form-preguntas-frecuentes">
          <div class="container-form-preguntas-frecuentes">
            <div
              style="
                display: flex;
                margin: auto;
                justify-content: center;
                margin-top: 1rem;
              "
            >
              <input
                style="width: 10rem"
                class="custom-input"
                v-model="message.nombre"
                placeholder="Nombre"
              />
              <input
                style="width: 10rem"
                class="custom-input"
                v-model="message.apellido"
                placeholder="Apellido"
              />
            </div>
            <div style="display: flex; margin: auto; justify-content: center">
              <input
                style="width: 22rem; position: relative; bottom: 1rem"
                class="custom-input"
                v-model="message.correo"
                placeholder="Email"
              />
            </div>
            <div style="display: flex; margin: auto; justify-content: center">
              <input
                style="
                  width: 22rem;
                  height: 10rem;
                  position: relative;
                  bottom: 4rem;
                "
                class="custom-input"
                v-model="message.mensaje"
                placeholder="Mensaje"
              />
            </div>
            <div style="display: flex; margin: auto; justify-content: center">
              <v-btn
                color="#0000FF"
                style="color: white; width: 80%"
                @click="sendContact()"
              >
                Enviar Pregunta
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    message: {
      nombre: "",
      apellido: "",
      correo: "",
      mensaje: "",
    },
    items: [
      {
        title: "¿Pregunta de prueba este texto es solo para muestra?",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,",
      },
      {
        title: "¿Pregunta de prueba este texto es solo para muestra?",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,",
      },
      {
        title: "¿Pregunta de prueba este texto es solo para muestra?",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,",
      },
      // ... más items
    ],
    expandedIndex: null,
  }),
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
    async sendContact() {},
    toggle(index) {
      this.expandedIndex = index === this.expandedIndex ? null : index;
    },
  },
  async mounted() {},
};
</script>

<style lang="scss">
@media screen and (min-width: 768px) {
  .mobile-preguntas-frecuentes-for-responsive {
    display: none !important;
  }
  .contianer-segment-for-freq-quest-mobile {
    display: none !important;
  }
  .segment-for-freq-quest-mobile {
    display: none;
  }
  .container-title-preguntas-frecuentes-mobile {
    display: none !important;
  }

  .container-faq-form-preguntas-frecuentes-mobile {
    display: none !important;
  }

  .container-form-preguntas-frecuentes-mobile {
    display: none !important;
  }
  .expansion-panel-acordeon-mobile {
    display: none !important;
  }
  .container-general-p {
    justify-content: center;
    display: flex;
    width: 100%;
    height: 50rem !important;
    background-color:#001E8C!important;
    background-size: cover;
    background-position: 0rem 0rem;
  }
}

@media screen and (max-width: 768px) {
  .pop-preguntas-freq {
    display: none !important;
  }
}

.expansion-panel-acordeon {
  margin-top: 3rem;
}

.accordion {
  width: 30rem;
}

.accordion-item {
  background-color: transparent;
  margin-bottom: 10px;
}

.accordion-header {
  border-bottom: 1px solid #686868;
  background-color: white;
  color: #686868;
  padding: 10px;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}

.accordion-content {
  color: #c2c2c2;
  display: none;
  padding: 10px;
}

.accordion-header:hover {
  background-color: #e0e0e0;
}
///fin acordeon

.custom-input::placeholder {
  color: black !important;
}

.custom-input {
  color: black;
  margin: 1rem;
  padding: 10px 0px;
  width: 20rem;
  border: none;
  border-bottom: 2px solid #e0e0e0;
  outline: none;
  background: none;

  &:focus {
    border-bottom: 2px solid #42a5f5;
    box-shadow: none;
  }

  &::placeholder {
    color: #9e9e9e;
  }
}

.container-faq-form-preguntas-frecuentes {
  display: flex;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

.container-form-preguntas-frecuentes {
  width: 410px;
  height: 427px;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  background-color: #eeeeee;
}

.title-preguntas-frecuentes {
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: 36.31px;
  text-align: left;
  color: #0000ff;
}

.title-preguntas-frecuentes-mobile {
  font-family: Inter;
  font-size: 25px;
  font-weight: 700;
  line-height: 36.31px;
  color: #0000ff;
}

.container-title-preguntas-frecuentes {
  display: flex;
  margin: auto;
  justify-content: flex-start;
}

.container-faqs-for-sub-title {
  display: flex;
  align-items: center;
}

.horizontal-line-for-faqs {
  width: 50px;
  height: 0px;
  border-top: 2px solid #0198cf;
  margin-right: 10px;
  /* Espacio entre la línea y el subtítulo */
}

.sub-title-faqs {
  width: fit-content;
  color: #0198cf;
}

.segment-for-freq-quest {
  width: 1036px;
  height: 503px;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  background-color: white;
}

.pop-preguntas-freq {
  display: flex;
  margin: auto;
  justify-content: center;
  position:relative;
}

.segment-for-freq-quest-mobile {
  width: 90%;
  height: max-content;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  background-color: white;
}

.accordion-mobile {
  width: 90%;
  padding-bottom: 2rem;
}

.accordion-item-mobile {
  background-color: transparent;
  margin-bottom: 10px;
}

.accordion-header-mobile {
  border-bottom: 1px solid #686868;
  background-color: white;
  color: #686868;
  padding: 10px;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}

.accordion-content-mobile {
  color: #c2c2c2;
  display: none;
  padding: 10px;
}

.accordion-header-mobile:hover {
  background-color: #e0e0e0;
}

.container-faq-form-preguntas-frecuentes-mobile {
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.container-form-preguntas-frecuentes-mobile {
  width: 85%;
  height: auto;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  background-color: #eeeeee;
}

.custom-input-mobile::placeholder {
  color: black !important;
}

.custom-input-mobile {
  color: black;
  margin: 1rem;
  padding: 10px 0px;
  width: 10rem;
  border: none;
  border-bottom: 2px solid #e0e0e0;
  outline: none;
  background: none;

  &:focus {
    border-bottom: 2px solid #42a5f5;
    box-shadow: none;
  }

  &::placeholder {
    color: #9e9e9e;
  }
}

.mobile-preguntas-frecuentes-for-responsive {
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  padding-bottom: 3rem;
  background-color: #001E8C;
}

.container-acordion-mobile-items {
  display: flex;
  margin: auto;
  justify-content: center;
}

.container-faqs-for-sub-title-mobile {
  text-align: left;
  width: fit-content;
  color: #0198cf;
  padding-left: 1rem;
  padding-top: 2rem;
  font-style: oblique;
}
</style>
