<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-transition"
    :max-width="width"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title class="text-h5">
        <v-icon large>{{ badge }}</v-icon>
      </v-card-title>
      <v-card-text class="text-center pt-4">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="secondary--text"
          color="primary"
          v-if="!actions"
          @click="close()"
        >
          Cerrar
        </v-btn>
        <template v-if="!!actions">
          <v-btn
            class="secondary--text"
            color="primary"
            v-for="(action, index) in actions"
            :key="index"
            @click="exec(action.handler)"
          >
            {{ action.label }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageDialog",
  props: {},
  data() {
    return {
      closeHandler: null,
      message: "",
      show: false,
      type: "success",
      width: "450",
      /**
       * Attributes
       *  - label
       *  - handler
       */
      actions: null
    };
  },
  computed: {
    badge() {
      let badge = null;
      if (this.type === "success") {
        badge = "fas fa-award";
      } else if (this.type === "error") {
        badge = "fas fa-ban";
      } else if (this.type === "decision") {
        badge = "fas fa-question";
      }
      return badge;
    }
  },
  methods: {
    open(opts) {
      if (opts.type) {
        checkType(opts.type);
        this.type = opts.type;
      }
      if (opts.width) {
        this.width = opts.width;
      }
      this.closeHandler = opts.closeHandler;
      this.actions = opts.actions;
      this.message = opts.message;
      this.show = true;
      this.$emit("open");
    },
    close() {
      this.show = false;
      if (this.closeHandler && this.closeHandler instanceof Function) {
        this.closeHandler();
        this.closeHandler = null;
      }
      this.$emit("close");
    },
    exec(handler) {
      if (handler) {
        handler();
      }
      this.close();
    }
  }
});

function checkType(type) {
  if (!["success", "error", "decision"].includes(type)) {
    throw new Error("Unsupported type");
  }
}
</script>
