
<template>
    <div id="father">
        <section class="container-info">
      <div class="right-container">
        <div id="header">
            <img
        style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
        src="../../../assets/line-horizontal.png"
        alt=""
      />
      <span style="font-style: oblique;padding-left: 1rem;">Escribenos</span>
    </div>
           
           <H1>Información de contacto</H1>
        
     

           <div class="align">
           
           
            <div class="info-text">
                <img src="../../../assets/map-icon.svg" class="icon" alt="">
                <span>
                  La dirección va escrita en este lugar
                </span>
            </div>

            <div class="info-text">
                <img src="../../../assets/mail-icon.svg" class="icon" alt="">
                <span >
                  correo@higher.com
                </span>
              </div>
           
            <div class="info-text">
                <img src="../../../assets/phone-icon.svg" class="icon" alt="">
                <span >
                  (000) 000 00 00
                </span>
            </div>

           </div>
         
      </div>
      <div class="container-inputs">
        <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field style="background-color: white; width: 100%;" v-model="firstname" :counter="10" :rules="nameRules" label="Nombre" hide-details required></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field style="background-color: white;width: 100%;" v-model="lastname" :counter="10" :rules="nameRules" label="Apellido" hide-details required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field style="background-color: white; width: 100%" v-model="email" :counter="10" :rules="emailRules" label="Email" hide-details required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                          <v-textarea  
                          v-model="text"
                         
                           
                          style=" text-decoration: none; background-color: white; margin-top: 1rem; width: 100%; height: 0rem;height: 0.1rem; " 
                          label="Mensaje" 
                          model-value="Your message here" 
                          autogrow
                           name="input-7-1">
                          </v-textarea>
                      </v-col>
                    </v-row>
                    
                        <v-btn style="width: 100%;margin-top: 12rem;color: white;" color="#001E8C">
                          enviar pregunta
                        </v-btn>
                  
                  </v-container>
                </v-form>
      </div>
</section>
</div>

</template>


<style lang="scss">

#father{
    display: flex;
    justify-content: center;
    margin-top:3rem;
    margin-bottom:3rem;
}
#header{
    align-items: start;
    margin-bottom: 2rem;
    color: #0198CF;
}
.info-text{
 display: flex;
    align-items: center;
    gap: 1rem;
}
.info-text span{
font-size: 1rem;
text-align: left;
margin-right:1rem ;
}

.container-info{
    display: flex;
    background-color: #001E8C;
    padding: 4rem;
    width: 70%;
    border-top-right-radius: 4rem;
    border-bottom-left-radius: 4rem;
    justify-content: space-between;
}
.container-info h1{
   font-size: 2rem;
   align-items: start;
   text-align: left;
   margin-bottom: 2rem;
}
.container-inputs{
    background-color: white;
    border-top-right-radius: 4rem;
    border-bottom-left-radius: 4rem;
}
.icon {
  padding: 0.3rem;
}    
.align{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    align-items: start;
    margin-left: 0.5rem;
    margin-top: 1rem;
}
.right-container{
    display: flex;
    flex-direction: column;
    color: white;
    align-items: start;
}
.user-info{
    display: flex;
}


@media only screen and (max-width: 735px) {
  #father{
    
    margin-bottom:0rem;
}
 
  .container-info{
  flex-direction: column;
  padding: 0rem;
  width: 100%;
  background-color: white;
}
.right-container{
background-color:  #001E8C;
border-top-right-radius: 4rem;
border-bottom-right-radius: 4rem;
padding-top:2rem ;
padding-bottom: 2rem;
box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.2);
}
.container-inputs{
  background-color: transparent; 
}
   
.align{
    
    margin-top: 0.3rem;
    margin-left: 1.5rem;
}
#header{
   font-size: 1.5rem;
}
.container-info h1{
  margin-left: 1rem;
}
.v-form{
  width: 100%;
}
}

</style>
<script>
export default {
  data() {
    return {
      valid: false,
      firstname: '',
      lastname: '',
      email: '',
      nameRules: [
        v => !!v || 'El nombre es requerido',
        v => (v && v.length <= 10) || 'El nombre debe tener menos de 10 caracteres',
      ],
      emailRules: [
        v => !!v || 'El email es requerido',
        v => /.+@.+\..+/.test(v) || 'El email debe ser válido',
      ],
    };
  },
};
</script>