<template>
  <v-card
    width="288px"
    style="border-radius: 0rem 2rem 0rem 2rem"
    height="342px"
    class="container-card"
    color="#658DC0"
  >
    <div class="container-form">
      <div>
        <img class="logo" src="../../assets/logo-corre-black.png" alt="" />
      </div>
      <div>
        <h2 class="information">
          Suscribete a nuestro <br />
          boletín informativo
        </h2>
      </div>
      <div>
        <v-text-field hide-details="auto" label="Correo"></v-text-field>
      </div>
      <div class="send-question-button">
        <v-btn color="success"> Suscribirse </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "card-abstract",
  data() {
    return {
      side: 4,
      center: 4
    };
  }
};
</script>

<style lang="css">
.container-form {
  padding: 2rem;
}
.send-question-button {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  * {
    width: 100%;
  }
}

.logo {
  margin-bottom: 15px;
}

.information {
  margin-bottom: 25px;
}
</style>
