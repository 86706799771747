import axios from "axios";

const firebaseEndpoint = "https://firebasetest.higherupcoaching.com/mailer/";

export default {
  async sendOrderConfirmation(order) {
    try {
      console.log(order);
      let response = await axios.post(
        `${firebaseEndpoint}orderConfirmation`,
        order
      );
      console.log(response.data);
      return await response.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  async sendAccountCreated(account) {
    try {
      console.log(account);
      let response = await axios.post(
        firebaseEndpoint + "accountCreated",
        account
      );
      console.log(response.data);
      return await response.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  async sendNewsletterSuscription(email) {
    try {
      console.log(email);
      let response = await axios.post(firebaseEndpoint + "newsletter", email);
      console.log(response.data);
      return await response.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },

  async sendContactMessage(data) {
    try {
      console.log(data);
      let response = await axios.post(firebaseEndpoint + "contact", account);
      console.log(response.data);
      return await response.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
};
