import mailService from "../../services/mailService";
export default {
  state: () => ({
    order: [],
    account: [],
  }),
  mutations: {
    setOrderConfirmationResponse(state, order) {
      state.order = order;
    },
    setAccountCreatedResponse(state, account) {
      state.account = account;
    },
  },
  actions: {
    async sendOrderConfirmation({ commit }, order) {
      try {
        let data = await mailService.sendOrderConfirmation(order);
        return data 
      } catch (error) {
        console.error(error);
        throw new Error(await error);
      }
    },
    async sendAccountCreated({ commit }, account) {
      try {
        console.log(account);
        let data = await mailService.sendAccountCreated(account);
        return data
      } catch (error) {
        console.error(error);
        throw new Error(await error);
      }
    },
    async sendNewsletterSuscription({ commit }, email) {
      try {
        let data = await mailService.sendNewsletterSuscription(email);
        return data;
      } catch (error) {
        console.error(error);
        throw new Error(await error);
      }
    },
    async sendContactMessage({ commit }, data) {
      try {
        let response = await mailService.sendContactMessage(data);
        return response;
      } catch (error) {
        console.error(error);
        throw new Error(await error);
      }
    },
  },
  getters: {},
};
