<template>
  <div class="super-container">
    <div class="banner-numbers" ref="bannerNumbers">
      <div class="img-background">
        <div v-for="(item, index) in estadistics" :key="index" class="estadistics-testimonials">
          <div class="estadistic-content">
            <h1 style="color: #658dc0; font-size: 5rem">
              {{ item.displayedNumber + item.title }}
            </h1>
            <span style="font-weight: 900">
              {{ item.content }}
            </span>
          </div>
          <div v-if="index < 3" class="vertical-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      estadistics: [
        {
          number: 20,
          title: "k+",
          content: "Personas certificadas",
          displayedNumber: 0
        },
        {
          number: 12,
          title: "k+",
          content: "Clientes felices",
          displayedNumber: 0
        },
        {
          number: 4,
          title: "k+",
          content: "Miembro de la comunidad",
          displayedNumber: 0
        },
        {
          number: 10,
          title: "k+",
          content: "Personas con membresia",
          displayedNumber: 0
        }
      ],
      observer: null
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: 0.5
    });
    this.observer.observe(this.$refs.bannerNumbers);
  },
  methods: {
    handleIntersection(entries) {
      if (entries[0].isIntersecting) {
        this.incrementNumbers();
        this.observer.unobserve(this.$refs.bannerNumbers);
      }
    },
    incrementNumbers() {
      this.estadistics.forEach((item, index) => {
        const increment = Math.ceil(item.number / 50);
        const interval = setInterval(() => {
          if (item.displayedNumber < item.number) {
            item.displayedNumber += increment;
          } else {
            clearInterval(interval);
          }
        }, 100);
      });
    }
  }
};
</script>

<style lang="scss">
.estadistic-content {
  margin: 2rem;
  margin-top: 5rem;
}

.vertical-line {
  border: 1px solid black;
  height: 8rem;
  margin-top: 5rem;

}

.estadistics-testimonials {
  display: flex;

}


@media screen and (max-width: 768px) {

  .vertical-line {
    display: none;
  }

  .banner-numbers {
    width: 60%;
    justify-content: center !important;
  }

  .estadistic-content {
    h1 {

      font-size: 3rem !important;
    }
  }

  .estadistics-testimonials {
    font-size: 1rem;
    flex-wrap: wrap !important;
    display: block !important;
  }
  .img-background{
    margin: 0rem ;
    background-image: none !important;
    font-size: 1rem;
    flex-wrap: wrap !important;
    display: block !important;
    margin-bottom: 25rem;
  }
  

}

.banner-numbers {
  justify-content: end;
  display: flex;
  margin: auto;
  width: 100%;
}

.img-background {
  width: 70%;
  height: 25rem;
  justify-content: end;
  display: flex;
  background-image: url("../../../assets/banner-numbers.svg");
}

.super-container {
  width: 100%;
  margin: auto;
  justify-content: end;
  background-color: white;
}
</style>
