<template>
  <div class="super-card-container">
    <div class="card-container-mobile-version-for-our-enterprise">
      <div>
        <img class="img-for-nuestra-empresa-mobile" src="../../../assets/nuestra-empresa.svg" alt="" />
      </div>
      <div>
        <div style="text-align: left; padding-left: 1rem; font-style: oblique; ">
            <img
            style="padding: 0.3rem 0.9rem 0.3rem 0.3rem"
            src="../../../assets/line-horizontal.png"
            alt=""
            />
            <span class="sub-title-sobre-nosotros-mobile">SOBRE NOSOTROS</span>
        </div>
        <p class="text-h4 font-weight-black" style="color: #0000FF; text-align: left; padding-left: 1rem; ">Nuestra empresa</p>
        <p style="text-align:initial ; padding: 1rem; padding-bottom: 1rem;">
            Higher Up Coaching es una empresa líder en coaching dedicada a
            potenciar a individuos y organizaciones para alcanzar su máximo
            potencial. Con un equipo de coaches experimentados y certificados,
            brindamos soluciones de coaching personalizadas adaptadas a las
            necesidades y metas únicas de nuestros clientes. Ya sea que busques
            crecimiento personal o que desees mejorar el rendimiento de tu
            equipo, Higher Up Coaching está aquí para guiarte en tu camino hacia
            el éxito.
        </p>
      </div>
    </div>
    
        <div id="container-desktop">
            
          <div id="img-container">
                   <img src="../../../assets/nuestra-empresa.svg" alt="">
          </div>
          <div style="padding: 2rem;text-align: left;">
             <div>
              <img style="padding: 0.3rem 0.9rem 0.3rem 0.3rem" src="../../../assets/line-horizontal.png" alt=""/>
              <span class="sub-title-sobre-nosotros-mobile">SOBRE NOSOTROS</span>
             </div>
        <p style="color:#0000FF;font-size: 2rem;font-weight: bold;">Nuestra empresa</p>
        <p style="width: 30rem;">
            Higher Up Coaching es una empresa líder en coaching dedicada a
            potenciar a individuos y organizaciones para alcanzar su máximo
            potencial. Con un equipo de coaches experimentados y certificados,
            brindamos soluciones de coaching personalizadas adaptadas a las
            necesidades y metas únicas de nuestros clientes. Ya sea que busques
            crecimiento personal o que desees mejorar el rendimiento de tu
            equipo, Higher Up Coaching está aquí para guiarte en tu camino hacia
            el éxito.
        </p>
        <button style="width: 12rem;margin-top:3rem;background-color: #0404FF;font-weight: 400;">SABER MAS</button>
          </div>
             
        </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    reveal: false
  })
};
</script>

<style lang="scss">
#container-desktop{
  display: flex;
  align-content: center;
  align-items: center;
  width: 70rem;
}
.card-container {
  margin: auto;
  display: flex;
  div {
    margin: 3rem;
  }
}

.super-card-container {
  background-image: url("../../../assets/background-1.svg");
  background-size: contain;
  width: 100%;
  margin-top: auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.container-text {
  text-align: start;
  
}

.container-button {
  margin-top: 2rem;
  display: flex;
}


.horizontal-line-sobre-nosotros {
  width: 50px;
  height: 0px;
  border-top: 2px solid #0198CF;
  margin-right: 10px;
}

.container-sobre-nosotros-for-sub-title{
  position: absolute;
  right: 24rem;
  top: 30rem;
  width: fit-content;
  display: flex;
  align-items: center;
}

.sub-title-sobre-nosotros{
  
  color:#0198CF;
}

.sub-title-sobre-nosotros-mobile{
  color:#0198CF;
}

@media screen and (max-width: 768px) {
 #container-desktop{
  display: none;
 }
  
}


@media screen and (max-width: 768px) {
  .card-container-desktop-version-for-our-enterprise{
  display: none !important;
  
}
}


@media screen and (min-width: 768px) {
  .card-container-mobile-version-for-our-enterprise{
    display:none !important;
  }
}

.img-for-nuestra-empresa-mobile {
  width: 15rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

</style>
