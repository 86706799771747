<template>
  <v-container style="margin-top: 7rem; margin-bottom: 3rem;">
    <h1 class="titulo-politicas-cookies">
      Politicas de Cookies
    </h1>
    <p class="text-politics-cookies">
   Las presentes “Políticas de cookies” tienen como finalidad hacer 
   del conocimiento de todos los usuarios de hello@higherupcoaching.com, 
   en adelante “Higher Up Coaching” Sean o no suscriptores de algún plan, los tipos de cookies que utilizamos 
   en nuestro sitio web y con qué finalidad lo hacemos.
    </p>
    <p class="text-politics-cookies">
      Esto con el propósito de que el usuario pueda libremente configurar su navegador para elegir aquellas 
      que desea aceptar, rechazar, modificar o eliminar.
    </p>
    <ol>

      <li class="pointers-cookies"> 
       <b>Definición:</b> Las cookies son archivos pequeños que se descargan en 
        tu dispositivo al acceder a “Higher Up Coaching” permitiéndonos almacenar 
        información sobre tus hábitos de comportamiento. Sin tu consentimiento expreso mediante 
        la activación de las cookies en tu navegador “Higher Up Coaching” no enlazará en las
         cookies datos memorizados con datos personales proporcionados en el momento de realizar 
        alguna transacción (adquirir una prueba “DISC”, Suscribir un plan, etc).
      </li>

      <li class="pointers-cookies">
       <b>Cookies que utilizamos en “Higher Up Coaching”:</b>  entre las cookies que utilizamos 
        podemos encontrar “cookies propias” que son aquellas que se instalan en tu terminal a 
        través del sitio web de “Higher Up Coaching” y cookies de terceros que son aquellas 
        que se instalan en tu terminal desde un dominio o página web que no es administrada por 
        “Higher Up Coaching” sino por otra identidad que trata la información obtenida a través 
        de las cookies:
        
        <ul>
         
          <li class="pointers-cookies">
             <b>Cookies técnicas:</b> son aquellas que te permiten navegar a través de la página web y utilizar 
            las distintas opciones o servicios que en ella existen tales como, controlar el tráfico y la 
            comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, 
            recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido 
            (ya sea un plan, una prueba o cualquier otro producto de “Higher Up Coaching”), 
            realizar la solicitud de inscripción o participación en un evento (si lo hubiese), 
            utilizar elementos de seguridad durante la navegación, almacenar 
            contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.
          </li>

          <li class="pointers-cookies">
            <b>Cookies de personalización:</b> son aquellas que te permiten acceder al 
            servicio con algunas características generales previamente definidas 
            en función de una serie de parámetros en tu terminal tales como: el 
            idioma que utilizas, el tipo de navegador mediante el cual accedes al 
            servicio, la región de donde accedes, entre otros.
          </li>

          <li class="pointers-cookies">
            <b>Cookies de análisis: </b>son aquellas que permiten a “Higher Up Coaching” 
            medir el número de usuarios y realizar análisis estadísticos respecto a su 
            comportamiento dentro del sitio web. Para ello, analizaremos tu comportamiento 
            dentro del sitio web, lo cual nos permitirá mejorar la oferta de los productos 
            y/o servicios que ofrecemos.
          </li>

          <li class="pointers-cookies">
            <b>Cookies publicitarias:</b> “Higher Up Coaching” podría utilizar cookies publicitarias 
            en los casos en que coloque anuncios en su sitio web. Estas cookies nos permitirían 
            gestionar la oferta de los espacios publicitarios que haya en la página web (si los hubiese), 
            adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realices 
            de nuestra página web. Para ello podemos analizar tus hábitos de navegación en Internet y 
            mostrarte publicidad relacionada con tu perfil de navegación.
          </li>
        
        </ul>
      </li>
      
         
      <li class="pointers-cookies">
        <b>Eliminación y desactivación de cookies:</b> Como usuario de “Higher Up Coaching” 
        tienes derecho a eliminar o deshabilitar las cookies que creas conveniente. 
        En este sentido, debes saber que los navegadores web son las herramientas encargadas 
        de almacenar las cookies y desde allí puedes efectuar tu derecho de eliminación o 
        desactivación de las mismas. Ni “Higher Up Coaching” ni sus representantes 
        legales pueden garantizar la correcta o incorrecta manipulación de las cookies 
        por parte de los mencionados navegadores.
      </li>

      <li class="pointers-cookies">
        <b>Aceptación de la política de cookies:</b> La aceptación de la presente política de cookies 
        implica que has sido informado de una forma clara y completa sobre el uso de dispositivos 
        de almacenamiento y recuperación de datos (cookies) así como que “Higher Up Coaching” 
        dispone de tu consentimiento para el uso de las mismas. Así mismo, la aceptación de la presente 
        política implica la conformidad con nuestras “políticas de privacidad” las cuales podrás encontrar 
        en el siguiente enlace: 
        <!-- AQUI AGREGAR LA RUTA -->
        <span>
          <a href="/politicas">Políticas de Privacidad - Higher Up Coachings</a>
        </span> 
      </li>
        
      <li class="pointers-cookies">
        <b>Resolución de disputas y notificaciones:</b> La presente “política” forma parte de nuestras 
        “políticas de privacidad” por tanto, toda controversia, disputa o reclamación que surja en virtud 
        del presente documento debe ser resuelta de conformidad con las mismas. Así mismo, toda notificación 
        o comunicación debe llevarse a cabo de conformidad con lo establecido en dichas políticas.
      </li>
      

    </ol>

    <h2 class="subtittle-cookies">PARÁGRAFO PRIMERO</h2>
      <p class="text-politics-cookies"> <b>“Higher Up”</b> utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc. con 
        domicilio en los Estados Unidos con sede central en 1600 Amphitheatre Parkway, Mountain View, California 94043.  
        Para la prestación de estos servicios, estos utilizan cookies que recopilan información, incluida tu dirección IP, 
        que será transmitida, tratada y almacenada por Google en los términos fijados en su web, Incluyendo la posible transmisión
        de dicha información a terceros por razones de exigencia legal o cuando dichos terceros procesen la información por cuenta
        de Google. Si deseas más información sobre el uso de Google Analytics puedes consultar el siguiente enlace: 
        https://policies.google.com/technologies/types?hl=es
      </p>
    <h2 class="subtittle-cookies">PARÁGRAFO SEGUNDO</h2>
      <p class="text-politics-cookies"> Como usuario de <b>“Higher Up Coaching” </b>
        podrás en cualquier momento elegir qué cookies quieres que funcionen mediante 
        la configuración del navegador; por ejemplo:
        <ul>
        <li class="pointers-cookies">Chrome, siguiendo <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">estas instrucciones.</a></li>
        <li class="pointers-cookies">Explorer, siguiendo <a href="https://support.microsoft.com/es-es/hub/4338813/windows-help">estas instrucciones.</a></li>
        <li class="pointers-cookies">Firefox, siguiendo <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">estas instrucciones.</a></li>
        <li class="pointers-cookies">Safari, siguiendo <a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=en_US">estas instrucciones.</a></li>
        <li class="pointers-cookies"> Opera, siguiendo <a href="https://www.opera.com/es/terms">estas instrucciones.</a></li>
       </ul>
      </p>
    <h2 class="subtittle-cookies" >PARÁGRAFO ÚNICO</h2>
    <p class="text-politics-cookies">En algunos casos es necesario instalar cookies para que el navegador no olvide la decisión del USUARIO 
      de no aceptación de las mismas.</p>
      
  </v-container>
</template>

<style>

.titulo-politicas-privacidad{
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.pointers-cookies{
text-align: justify;
padding-top: 1rem;
margin-top: 0.3rem;
margin-bottom: 0.3rem;
}
.text-politics-cookies{
  text-align:justify;
}
.subtittle-cookies{
  text-align: left;
  padding-top: 1rem;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([])
  },
  async mounted() { 
  }
};
</script>
