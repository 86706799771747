<template>
  <div class="container-banner-corp content">
    <div style="margin-top: 5rem;">
      <img
        style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
        src="../../../assets/line-horizontal.png"
        alt=""
      />
      <span class="sub-menu"> Test Corporativo </span>
    </div>
    <h1 class="mt-8 color-white">Adquiere tu Membresia</h1>
    <div class="container-corp-product-cards-for-responsive" style="width:100% !important; background: transparent !important;">
      <ProductCards style="width:100% !important; background: transparent !important;"/>
    </div>
  </div>
</template>

<style>

@media screen and (max-width: 768px) {
  .container-corp-product-cards-for-responsive{
    display: flex !important;
    margin: auto !important;
    margin-left: -1.5rem !important;
    justify-content: center !important;
    width: 100%  !important;

  }

  .container-banner-corp{
    margin-top: -3rem !important;
  }
}
.sub-menu {
  color: #0198CF;
  font-style: italic;
}

.container-banner-corp {
  background-size: cover;
  background-color: #001E8C;
  border-bottom-left-radius: 4rem !important;
}

.color-white {
  color: white;
}

.content {
  display: flex;
  text-align: start;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  width: 100%;
}

@media screen and (max-width: 768px){
  .sub-menu {
    color: #0198CF;
    font-size: 1rem !important;
    font-style: italic;
  }
}
</style>

<script>
import ProductCards from './ProductCards.vue'


export default {
  components:{
    ProductCards,
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth"
        });
      }
    }
  }
};
</script>
