<template>
  <v-container>
    <div class="container-general-pay-now">
      <div class="container-pay-now">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <button style="background-color: #0000FF;" @click="buy()" :disabled="isLoading">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">Pay now</span>
        </button>
        <div id="payment-message" class="hidden"></div>
      </div>
    </div>
  </v-container>
</template>
<style>
form {
  width: 30vw;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
  .container-pay-now{
    min-width: 21rem !important;
  }
  .container-general-pay-now{
    justify-content: center;
    margin: auto;
    display: flex;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    clientSecret: "",
    elements: "",
    isLoading: false,
    stripe: Stripe(
      "pk_test_51Ja1fNDffCWMH2vd03RJDAmsD35fSVKaQwoV7TwcDydtU1F1BVlHADXVZtktpMD6leTZhLQJgPikAxDX2OtkhTbX002S9hIGfX"
    ),
  }),

  computed: {
    ...mapGetters([
      "getSelectedPaymentMethod",
      "getSelectedProduct",
      "getOrderInfo",
      "getClientSecret",
    ]),
  },
  methods: {
    ...mapActions(["createPaymentElement"]),

    showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageContainer.textContent = "";
      }, 4000);
    },

    // Show a spinner on payment submission
    async buy() {
      this.isLoading = true;
      const { submitError } = await this.elements.submit();
      if (submitError) {
        this.showMessage(error.message);
        return;
      }
      let website = "https://test.higherupcoaching.com";
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        clientSecret: this.getClientSecret,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${website}/checkout/thank-you?orderId=${this.getOrderInfo.id}`,
        },
      });
      console.log(elements);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        this.showMessage(error.message);
      } else {
        this.showMessage("An unexpected error occurred.");
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    console.log(this.getSelectedProduct);
    console.log(this.getOrderInfo.id);

    let body = { items: this.getSelectedProduct };

    await this.createPaymentElement({
      body,
      orderId: this.getOrderInfo.id,
    });
    const appearance = {
      theme: "stripe",
    };
    this.elements = this.stripe.elements({
      appearance,
      clientSecret: this.getClientSecret,
    });

    const paymentElementOptions = {
      layout: "accordion",
      radios: true,
    };
    const paymentElement = this.elements.create(
      "payment",
      paymentElementOptions
    );
    paymentElement.mount("#payment-element");
  },
};
</script>
