<template>
  <v-card
    class="pb-0"
    :class="color"
    :max-width="maxWidth"
    :min-height="minHeight"
  >
    <v-card-title class="subtitle-1 font-weight-black" v-if="!!title">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
    <v-card-actions class="align-end">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: "secondary"
    },
    maxWidth: {
      type: String,
      required: false
    },
    minHeight: {
      type: String,
      required: false
    }
  }
});
</script>

<style>
.v-card__title {
  text-align: center !important;
  text-transform: uppercase !important;
  display: block;
}
</style>
