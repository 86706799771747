<template>
  <v-container class="pa-0" fluid>
    <Banner />
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import Banner from "./components/Banner.vue";

export default defineComponent({
  components: {
    Banner
  }
});
</script>
