<template>
  <div style="margin-top: 10rem;" class="min-h-screen bg-gray-50">
    <h1 class="text-3xl font-bold mb-8">
      Politicas de Privacidad
    </h1>
    <div style="text-align: start !important;" class="max-w-4xl mx-auto px-4 py-16">
      
      <div class="prose max-w-none">
        <p class="mb-6">
          Queremos darte la más cordial Bienvenida a hello@higherupcoaching.com y hacemos de tu conocimiento que higherupcoaching.com y app.higherupcoaching.com en adelante "Higher Up Coaching".
        </p>

        <p class="mb-6">
          Son plataformas propiedad de Higher Up Coaching, entidad que actua como representante exclusivo de la marca y de los negocios de coaching conducidos por "Higher Up Coaching" registrada y domiciliada en la ciudad de Orlando, Florida, Estados Unidos de Norte América, que tiene como finalidad la distribución y venta de la prueba psicométrica universal DISC, entre otras actividades y servicios de coaching empresarial y personal.
        </p>

        <p class="mb-6">
          Estas políticas de privacidad (las "Políticas") describen la información que "Higher Up Coaching" recopila a través de Sitios Web y aplicaciones tanto propias como de terceros y el modo en que la misma es utilizada, mantenida y, en algunos casos, compartida.
        </p>

        <p class="mb-6">
          Para nosotros es gratificante saber que utilizas nuestros sitios web, aplicaciones, productos y/o servicios y estamos comprometidos a proteger tu privacidad. Por esta razón, se comparte tu información solo en la manera en que se describe en este documento.
        </p>

        <p class="mb-6">
          Comprometiéndonos a informarte en caso de existir alguna eventualidad no contemplada en las presentes "Políticas":
        </p>

        <p class="mb-6">
          Al visitar o utilizar los Sitios Web, servicios, aplicaciones y cualesquiera otras páginas, características o contenidos en los Sitios Web, reconoces que aceptas las normas y políticas aquí detalladas.
        </p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">1. Aplicación de "las políticas"</h2>
        <p class="mb-6">
          Las presentes políticas se aplican a todos los usuarios de "Higher Up Coaching" sean o no suscriptores de la plataforma. Los titulares de cuentas deben velar por la integridad y confidencialidad de los datos e información de terceros que tengan a bien manejar. "Higher Up Coaching" No se hace responsable por el uso que realice el titular de una cuenta respecto a la información de sus usuarios, clientes, colaboradores o cualquier otro con quien sea compartida dicha información.
        </p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">2. Derechos sobre los datos</h2>
        <p class="mb-4">
          Todos los usuarios, suscriptores y terceros cuyos datos sean proporcionados a "Higher Up Coaching", A través de alguno de sus instrumentos, productos o servicios tienen los siguientes derechos:
        </p>
        <ul class="list-disc pl-6 mb-6">
          <li>Solicitar el acceso a los datos almacenados.</li>
          <li>Solicitar la rectificación o la eliminación de los mismos.</li>
          <li>Solicitar la limitación del tratamiento de los datos.</li>
          <li>Oponerse al tratamiento de los datos.</li>
          <li>Solicitar la portabilidad de los datos.</li>
        </ul>

        <p class="mb-6">
          Para realizar alguna de estas peticiones envía un correo electrónico a: hello@higherupcoaching.com con la copia de tu documento de identidad o equivalente con el cual podamos corroborar tu información.
        </p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">3. Principios aplicados al tratamiento de datos</h2>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-3">
            <strong>Principio de licitud, lealtad y transparencia:</strong> Siempre se requerirá el consentimiento para el tratamiento de tus datos y se te informará con absoluta transparencia los fines para los cuales son utilizados.
          </li>
          <li class="mb-3">
            <strong>Principio de minimización de datos:</strong> "Higher Up Coaching" solo te solicitará los datos necesarios acorde con el fin para el cual están destinados.
          </li>
          <li class="mb-3">
            <strong>Principio de limitación del plazo de conservación:</strong> "Higher Up Coaching" Se compromete a mantener los datos únicamente el plazo que sea necesario para el fin que hayan sido recolectados.
          </li>
          <li>
            <strong>Principio de integridad y confidencialidad:</strong> Todos los datos serán tratados de tal forma que su seguridad y confidencialidad estén garantizadas.
          </li>
        </ul>

        <div class="bg-gray-100 p-4 rounded-lg mb-6">
          <h3 class="font-semibold mb-2">PARÁGRAFO ÚNICO</h3>
          <p>
            En el caso de suscriptores, "Higher Up Coaching" Se compromete a revisar periódicamente las listas, eliminando a todos aquellos que se encuentren inactivos durante un tiempo considerable.
          </p>
        </div>

        <h2 class="text-2xl font-semibold mt-8 mb-4">4. Información personal recolectada</h2>
        <p class="mb-6">
          A efectos de las presentes "políticas" se entiende por "información personal" aquella que identifica, relaciona, describe o que puede asociarse de manera directa o indirecta con algún usuario.
        </p>

        <h3 class="text-xl font-semibold mt-6 mb-3">4.1 Identificadores únicos y datos de geolocalización</h3>
        <ul class="list-disc pl-6 mb-6">
          <li>La dirección del protocolo de internet (IP) y de su ubicación</li>
          <li>Información de tu proveedor de servicios de internet (ISP)</li>
          <li>Información sobre los dominios visitados</li>
          <li>Fecha y hora de entrada al sitio web</li>
          <li>La URL de referencia</li>
          <li>Información sobre el buscador utilizado</li>
          <li>Sistema operativo e información relacionada</li>
        </ul>

        <h3 class="text-xl font-semibold mt-6 mb-3">4.2 Identificadores directos</h3>
        <ul class="list-disc pl-6 mb-6">
          <li>Nombres y apellidos</li>
          <li>Dirección de residencia u oficina</li>
          <li>Números de teléfono</li>
          <li>Dirección de correo electrónico</li>
          <li>Perfil en redes sociales</li>
          <li>Fecha de nacimiento</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-8 mb-4">5. Medios de recolección de datos</h2>
        <ul class="list-disc pl-6 mb-6">
          <li>Formularios</li>
          <li>Comentarios en el blog</li>
          <li>Inscripción en boletines</li>
          <li>Registro para membresías</li>
          <li>Llenado de encuestas</li>
          <li>Participación en concursos</li>
          <li>Realización de prueba "DISC"</li>
          <li>Envío de correo electrónico</li>
        </ul>

        <div class="bg-gray-100 p-4 rounded-lg mb-6">
          <h3 class="font-semibold mb-2">PARÁGRAFO ÚNICO</h3>
          <p>
            Como usuario eres responsable de elegir el medio y la información que compartes a través del mismo y eres consciente de que algunos de estos medios son públicos.
          </p>
        </div>

        <h2 class="text-2xl font-semibold mt-8 mb-4">6. Almacenamiento de datos por terceros</h2>
        <p class="mb-4">
          "Higher Up Coaching" utiliza las siguientes plataformas de terceros:
        </p>
        <ul class="list-disc pl-6 mb-6">
          <li>Firebase: Para almacenamiento de datos y desarrollo de aplicaciones</li>
          <li>Sendgrid: Para envío y recepción de correos electrónicos</li>
          <li>Stripe: Para procesamiento de transacciones financieras</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-8 mb-4">7. Uso de información personal</h2>
        <p class="mb-6">
          "Higher Up Coaching" no venderá ni rentará tu información personal. Sin embargo, la información podría ser compartida con terceros en los siguientes casos:
        </p>
        <ul class="list-disc pl-6 mb-6">
          <li>Cuando el proveedor de hosting lo requiera</li>
          <li>Para efectos de marketing y ventas</li>
          <li>Para mejorar la calidad en la prestación de servicios</li>
          <li>Por requerimiento judicial o gubernamental</li>
          <li>Para proteger derechos de la compañía o terceros</li>
          <li>En caso de adquisición de la compañía</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-8 mb-4">8. Menores de edad</h2>
        <p class="mb-6">
          Los servicios y productos que "Higher Up Coaching" ofrece no están dirigidos ni guardan relación con menores de edad. No se solicita ni recopila de forma consciente información de estos.
        </p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">9. Enlaces a sitios web</h2>
        <p class="mb-4">
          Los sitios web de "Higher Up Coaching" contienen enlaces a otras redes sociales:
        </p>
        <ul class="list-disc pl-6 mb-6">
          <li>Twitter</li>
          <li>Facebook</li>
          <li>LinkedIn</li>
          <li>Instagram</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-8 mb-4">10. Seguridad</h2>
        <p class="mb-6">
          Utilizamos los servicios de "Firebase" para mantener los más altos estándares de seguridad. Sin embargo, ninguna transmisión de información a través de internet puede garantizarse como 100% segura.
        </p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">11. Resolución de disputas</h2>
        <p class="mb-6">
          Toda controversia será sometida inicialmente a un proceso de negociación directa a través de hello@higherupcoaching.com. En caso de no resolverse, se someterá a la jurisdicción de los tribunales del estado de la Florida.
        </p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">12. Modificaciones</h2>
        <p class="mb-6">
          "Higher Up Coaching" se reserva el derecho a modificar las presentes "políticas" para adaptarlas a novedades legislativas o jurisprudenciales así como a prácticas de la industria.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: 'Politicas de Privacidad - Higher Up Coaching',
    meta: [
      {
        name: 'description',
        content: 'Políticas de privacidad de Higher Up Coaching. Información sobre el tratamiento de datos personales y derechos de los usuarios.'
      }
    ]
  }
}
</script>

<style>


.prose {
  max-width: 100%;
  font-size: 1rem;
  line-height: 1.75;
}

.prose p {
  margin-bottom: 1.5rem;
}

.prose h2 {
  color: #1a202c;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.prose ul {
  margin-bottom: 1.5rem;
}

.prose li {
  margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
  .prose {
    font-size: 0.875rem;
  }
}
</style>