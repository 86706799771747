import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import auth from "./modules/auth";
import shop from "./modules/shop";
import product from "./modules/product";
import mail from "./modules/mail";
import payment from "./modules/payment";

export default new Vuex.Store({
  modules: {
    auth,
    mail,
    shop,
    product,
    payment
  },
  plugins: [
    new VuexPersistence({
      key: "globaldisc-platform",
      storage: window.localStorage,
      modules: ["auth"]
    }).plugin
  ]
});
