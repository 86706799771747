<template>
  <o-text-field
    type="email"
    :disabled="disabled"
    :label="label"
    :rules="rules"
    :value="value"
    @change="onChange"
    @input="onInput"
    @keydown="onKeydown"
  ></o-text-field>
</template>

<script>
import { defineComponent } from "vue";

import validations from "@/plugins/validations";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: "Correo Electrónico"
    },
    value: String
  },
  data() {
    return {
      rules: [validations.form.email]
    };
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    },
    onInput(event) {
      this.$emit("input", event);
    },
    onKeydown(event) {
      this.$emit("keydown", event);
    }
  }
});
</script>
