<template>
  <o-text-field
    required
    type="password"
    :label="label"
    :value="value"
    @change="onChange"
    @input="onInput"
    @keydown="onKeydown"
  ></o-text-field>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
      default: "Contraseña"
    },
    value: String
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    },
    onInput(event) {
      this.$emit("input", event);
    },
    onKeydown(event) {
      this.$emit("keydown", event);
    }
  }
});
</script>
