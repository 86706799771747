import firebase from "firebase";

import "firebase/auth";
import "firebase/firestore";

import firebaseConfig from "@/config/firebase";

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// TODO: Improve this export by injecting the signout callback in some way
export default firebase;
