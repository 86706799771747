<template>
  <v-container style="margin-top: 5rem;" class="terms-container">
    <v-card class="pa-6">
      <v-card-title class="text-h4 mb-4">
        Términos y Condiciones
      </v-card-title>

      <v-card-text class="terms-content">
        <!-- Propiedad del sitio web -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Propiedad del sitio web</h2>
          <p>
            higherupcoaching.com y app.higherupcoaching.com en adelante "Higher Up Coaching" son
            plataformas propiedad de Higher Up Coaching, registrada y domiciliada en la ciudad de Orlando, 
            Florida, Estados Unidos de Norte América, que tiene como finalidad la distribución y venta 
            de la prueba psicométrica universal DISC, entre otras actividades y servicios de coaching 
            empresarial y personal.
          </p>
        </section>

        <!-- Objeto -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Objeto</h2>
          <p>
            El presente documento tiene como finalidad regular las condiciones de uso, navegación y 
            acceso dentro del website, así como de los contenidos que hacen parte del mismo. Al utilizar 
            alguna de las plataformas de Higher Up Coaching se aceptan los presentes términos y condiciones, 
            así como las políticas de privacidad y las políticas de cookies, pudiendo estos ser actualizados 
            y/o modificados en cualquier momento sin previo aviso.
          </p>
        </section>

        <!-- Acceso y uso -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Acceso y uso del sitio web</h2>
          <p>
            Salvo que sea indicado lo contrario, el acceso por parte de los usuarios a las plataformas 
            propiedad de "Higher Up Coaching" es gratuito. Por tanto, el simple hecho de acceder a dichas 
            plataforma no implica una relación comercial entre "Higher Up Coaching" y el usuario.
          </p>
          
          <v-card class="my-3 pa-3" outlined>
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO PRIMERO</h3>
            <p>
              Los usuarios se comprometen a utilizar la plataforma de conformidad con la ley, los 
              presentes "términos y condiciones", las políticas de privacidad, las políticas de cookies, 
              los acuerdos a que se haya dado lugar, el orden público y las buenas costumbres.
            </p>
          </v-card>

          <v-card class="my-3 pa-3" outlined>
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO SEGUNDO</h3>
            <p>
              "Higher Up Coaching" se reserva el derecho de suspender, cancelar o negar el registro de 
              los individuos que considere que están infringiendo los presentes términos y condiciones.
            </p>
          </v-card>

          <v-card class="my-3 pa-3" outlined>
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO TERCERO</h3>
            <p>
              Cada usuario es responsable de mantener la confidencialidad de los datos que proporciona 
              dentro de la plataforma tales como correo electrónico, contraseñas, datos de identidad, 
              entre otros.
            </p>
          </v-card>
        </section>

        <!-- Uso del contenido -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Uso del contenido del website</h2>
          <p>El usuario se compromete a utilizar los contenidos del portal de forma diligente y lícita absteniéndose de:</p>
          <v-list dense>
            <v-list-item v-for="(item, index) in prohibitedUses" :key="index">
              <v-list-item-icon>
                <v-icon color="error">mdi-close-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </section>

        <!-- Contratación de servicios -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Contratación de servicios</h2>
          <p>
            "Higher Up Coaching" ofrecerá distintos planes de suscripción anual en sus dos modalidades: 
            profesional y empresarial. Los mismos están dirigidos a coaches, psicólogos, gerentes de 
            recursos humanos y líderes en general.
          </p>

          <v-card class="my-3 pa-3" outlined v-for="(parrafo, index) in contratacionParrafos" :key="'cont'+index">
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO {{ parrafo.numero }}</h3>
            <p>{{ parrafo.contenido }}</p>
          </v-card>
        </section>

        <!-- Métodos de pago -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Métodos de pago</h2>
          <p>
            "Higher Up Coaching" utiliza los servicios de terceros para procesar los pagos de los usuarios 
            mediante tarjetas de crédito, ("procesador de pago"), o cualquier otro método de pago bancario 
            que sea acordado por "Higher Up Coaching" directamente con el cliente.
          </p>

          <v-card class="my-3 pa-3" outlined v-for="(parrafo, index) in metodoPagoParrafos" :key="'pago'+index">
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO {{ parrafo.numero }}</h3>
            <p>{{ parrafo.contenido }}</p>
          </v-card>
        </section>

        <!-- Cancelación de servicios -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Cancelación de servicios</h2>
          <p>
            Todo usuario tendrá derecho a cancelar la suscripción a cualquiera de los planes en cualquier 
            momento, enviando un correo electrónico a: hello@higherupcoaching.com
          </p>

          <v-card class="my-3 pa-3" outlined v-for="(parrafo, index) in cancelacionParrafos" :key="'canc'+index">
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO {{ parrafo.numero }}</h3>
            <p>{{ parrafo.contenido }}</p>
          </v-card>
        </section>

        <!-- Políticas de reembolso -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Políticas de reembolso</h2>
          <p>
            Todo usuario tendrá derecho a solicitar el reembolso parcial o total del monto de su 
            suscripción, para lo cual deberá enviar un correo electrónico motivando su solicitud a: 
            hello@higherupcoaching.com
          </p>

          <v-card class="my-3 pa-3" outlined>
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO ÚNICO</h3>
            <p>Dicho monto está sujeto a la discrecionalidad de "Higher Up Coaching" y al cumplimiento 
              de la totalidad de las siguientes condiciones:</p>
            <v-list dense>
              <v-list-item v-for="(condition, index) in reembolsoConditions" :key="index">
                <v-list-item-icon>
                  <v-icon>mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ condition }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </section>

        <!-- Responsabilidades -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Responsabilidades</h2>
          <p>
            "Higher Up Coaching" no puede garantizar la calidad, moralidad, utilidad, exactitud o 
            veracidad de las opiniones, datos e información cualquiera sea su origen que no sea de su 
            propiedad y que pudiesen encontrarse en el website.
          </p>

          <v-list dense class="mb-3">
            <v-subheader>Higher Up Coaching no se hace responsable de:</v-subheader>
            <v-list-item v-for="(resp, index) in responsabilidades" :key="index">
              <v-list-item-icon>
                <v-icon>mdi-alert-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ resp }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card class="my-3 pa-3" outlined v-for="(parrafo, index) in responsabilidadParrafos" :key="'resp'+index">
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO {{ parrafo.numero }}</h3>
            <p>{{ parrafo.contenido }}</p>
          </v-card>
        </section>

        <!-- Derechos de autor -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Derechos de autor</h2>
          <p>
            "Higher Up Coaching" es titular sobre los derechos de explotación en materia de propiedad 
            intelectual, industrial y de imagen sobre los contenidos disponibles en el sitio web.
          </p>

          <v-card class="my-3 pa-3" outlined v-for="(parrafo, index) in derechosParrafos" :key="'der'+index">
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO {{ parrafo.numero }}</h3>
            <p>{{ parrafo.contenido }}</p>
          </v-card>
        </section>

        <!-- Enlaces de terceros -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Enlaces de terceros</h2>
          <p>
            "Higher Up Coaching" puede incluir enlaces de terceros en su sitio web, lo cual no implica 
            una invitación a la contratación de productos o servicios que se ofrezcan en ellos.
          </p>

          <v-card class="my-3 pa-3" outlined>
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO ÚNICO</h3>
            <p>
              "Higher Up Coaching" no será responsable en ningún aspecto con relación a los sitios web 
              con los que se pueda establecer enlaces.
            </p>
          </v-card>
        </section>

        <!-- Resolución de disputas -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Resolución de disputas</h2>
          <p>
            Toda controversia, diferencia o reclamación que surja de los presentes términos y 
            condiciones será sometida a un proceso de negociación directa.
          </p>

          <v-card class="my-3 pa-3" outlined v-for="(parrafo, index) in disputasParrafos" :key="'disp'+index">
            <h3 class="text-subtitle-1 font-weight-bold mb-2">PARÁGRAFO {{ parrafo.numero }}</h3>
            <p>{{ parrafo.contenido }}</p>
          </v-card>
        </section>

        <!-- Notificaciones y Derecho aplicable -->
        <section class="mb-6">
          <h2 class="text-h5 mb-3">Notificaciones</h2>
          <p>
            En el caso en que las notificaciones a través del correo electrónico sean infructuosas las 
            mismas deben ser dirigidas a la siguiente dirección postal: 14558 CLARKSON DR. ORLANDO, FL, 
            32828. U.S.A
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-h5 mb-3">Derecho aplicable</h2>
          <p>
            Para la interpretación y todo aspecto relativo a los presentes términos y condiciones, las 
            partes acuerdan aplicar la legislación del estado de la Florida.
          </p>
        </section>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  
  data() {
    return {
      prohibitedUses: [
        'Utilizarlos con fines contrarios a la ley, la moral, las buenas costumbres y el orden público',
        'Utilizarlo con fines que puedan lesionar los derechos de terceros',
        'Publicar datos personales de terceros',
        'Provocar daños en los sistemas de "Higher Up Coaching"',
        'Introducir o difundir en la red cualquier tipo de malware'
      ],
      contratacionParrafos: [
        {
          numero: 'PRIMERO',
          contenido: 'Para poder acceder a dichos planes debe cancelarse la totalidad de la suscripción según el plan elegido.'
        },
        {
          numero: 'SEGUNDO',
          contenido: '"Higher Up Coaching" podrá ofrecer productos adicionales a la contratación de las suscripciones.'
        },
        {
          numero: 'TERCERO',
          contenido: '"Higher Up Coaching" se reserva el derecho de modificar en cualquier momento el contenido y las condiciones de contratación de cualquiera de sus productos y/o servicios.'
        }
      ],
      metodoPagoParrafos: [
        {
          numero: 'PRIMERO',
          contenido: 'Actualmente, "Higher Up Coaching" utiliza "Stripe" como pasarela de pago, por lo cual, el usuario acepta los términos y políticas de dicha plataforma.'
        },
        {
          numero: 'SEGUNDO',
          contenido: 'Cualquier inconveniente, duda u observación deberá canalizarla a través del correo electrónico: hello@higherupcoaching.com'
        }
      ],
      cancelacionParrafos: [
        {
          numero: 'PRIMERO', 
          contenido: '"Higher Up Coaching" se reserva el derecho de aceptar o negar dicha suscripción.'
        },
        {
          numero: 'SEGUNDO',
          contenido: 'La cancelación de una cuenta no implica necesariamente ningún tipo de reembolso o reversión de dinero por parte de "Higher Up Coaching".'
        }
      ],
      reembolsoConditions: [
        'El reembolso debe ser solicitado en un plazo no mayor a cinco (5) días luego de adquirida la suscripción.',
        'El reembolso puede ser solicitado siempre que no se hayan consumido más del 5% de las pruebas del plan adquirido.',
        'El reembolso podrá ser solicitado siempre y cuando no se haya completado la certificación como "Especialista Internacional Higher Up Coaching".'
      ],
      responsabilidades: [
        'Los perjuicios que un tercero pueda generar por la infracción de los términos y condiciones.',
        'Cualquier perjuicio por la vulneración de los sistemas de seguridad.',
        'Los perjuicios del mal funcionamiento del servidor.',
        'Los perjuicios por el funcionamiento defectuoso de sitios enlazados.'
      ],
      responsabilidadParrafos: [
        {
          numero: 'PRIMERO',
          contenido: 'Queda prohibida cualquier comunicación o transmisión de contenido que infrinja los derechos de terceros, así como todo contenido amenazante, intimidatorio o xenófobo.'
        },
        {
          numero: 'SEGUNDO',
          contenido: 'El usuario será el único responsable por cualquier perjuicio que cause a "Higher Up Coaching" de forma directa o indirecta.'
        },
        {
          numero: 'TERCERO',
          contenido: 'El usuario acepta que la utilización del sitio web será de su exclusivo riesgo.'
        }
      ],
      derechosParrafos: [
        {
          numero: 'PRIMERO',
          contenido: 'Quedan reservados todos los derechos de propiedad intelectual e industrial sobre los contenidos.'
        },
        {
          numero: 'SEGUNDO',
          contenido: 'El acceso y navegación del usuario por el sitio web en ningún caso se entenderá como una licencia o cesión de derechos.'
        }
      ],
      disputasParrafos: [
        {
          numero: 'PRIMERO',
          contenido: 'El proceso de negociación directa se realizará en línea a través de videoconferencias vía Zoom, Google Meet o cualquier otra herramienta a elección de las partes.'
        },
        {
          numero: 'SEGUNDO',
          contenido: 'Los acuerdos a que se llegue en el proceso de negociación directa serán plasmados por escrito y firmados por las partes interesadas.'
        },
        {
          numero: 'TERCERO',
          contenido: 'Las partes se obligan a no divulgar directa o indirectamente ninguna información o datos derivados del proceso de negociación.'
        },
        {
          numero: 'CUARTO',
          contenido: 'En caso de que las disputas no sean resueltas en el plazo de los 30 días concedidos para el proceso de negociación directa las mismas serán sometidas a la jurisdicción de los tribunales de la Florida.'
        }
      ]
    }
  },

  head() {
    return {
      title: 'Términos y Condiciones - Higher Up Coaching',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Términos y condiciones de uso de la plataforma Higher Up Coaching'
        }
      ]
    }
  }
}
</script>

<style scoped>
.terms-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.terms-content {
  line-height: 1.6;
}

.terms-content section {
  margin-bottom: 2rem;
}

.terms-content h2 {
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 0.5rem;
}

.terms-content p {
  margin-bottom: 1rem;
  text-align: justify;
  color: #444;
}

.v-card {
  background-color: #f9f9f9;
}

.v-list-item {
  padding-left: 0;
}

.v-list-item__title {
  font-size: 0.95rem;
  line-height: 1.4;
}

.text-subtitle-1 {
  color: #1976d2;
}

@media (max-width: 600px) {
  .terms-container {
    padding: 1rem;
  }
  
  .terms-content h2 {
    font-size: 1.5rem;
  }
  
  .v-card {
    margin: 0.5rem 0;
  }
}

@media print {
  .terms-container {
    padding: 0;
  }
  
  .v-card {
    box-shadow: none !important;
    border: 1px solid #ddd;
  }
}
</style>