<template>
  <v-container class="pa-0"  fluid>
    <Carousel />
    <Carousel_Logos id="logos" style="margin-top: 2rem; margin-bottom: 0rem"/>
    <CardNuestraEmpresa />
    <div class="container-background-servicios-especializados">
      <ServiciosEspecializados />
    </div>
    <PreguntaFrecuentes />
  </v-container>
</template>

<style>

 @media screen and (max-width: 768px) {
  #logos{
 display: none;
}
}
</style>
<script>
import { defineComponent } from "vue";
import Carousel from "./components/Carousel.vue";
import { mapGetters, mapActions } from "vuex";
import CardNuestraEmpresa from "./components/CardNuestraEmpresa.vue";
import ServiciosEspecializados from "./components/ServiciosEspecializados.vue";
import PreguntaFrecuentes from "./components/PreguntaFrecuentes.vue";
import Carousel_Logos from "./components/Carousel_Logos.vue"
export default defineComponent({
  components: {
    Carousel,
    Carousel_Logos,
    CardNuestraEmpresa,
    ServiciosEspecializados,
    PreguntaFrecuentes,
    
  },
  created() {},
  computed: {
    ...mapGetters(["getProducts", "getCategories"]),
  },
  methods: {
    ...mapActions(["updateProducts", "updateCategories"]),
  },

  async mounted() {
    let data = await Promise.all([this.updateProducts(), this.updateCategories()]);
  },
});
</script>

